import React, { Component } from 'react';
import $ from 'jquery';
import './inviteuser.scss'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Grid from '@material-ui/core/Grid';

import TemporaryUser from './temporary_list';
import ManagementUser from './management_list';

import Navbar from '../../Config/Navbar';
import { withTranslation } from 'react-i18next';


class UserTab extends Component {
  constructor(props){
    super(props);
    this.state = {

    }
  }

  componentDidMount(){
        var navBarHeight = $('.navBar').outerHeight()
        $('.tab_parent').css({
          paddingTop : navBarHeight+60,
        })
  }

  render(){
    const {t} = this.props
    return (
    <div>
    <Navbar/>
      <div className="tab_parent">
      <Grid container>
        <Grid item xs className="tableHeading">
            <label>{t('iv.header')}</label>
        </Grid>
        <Grid item xs>
            <button className="btn orange-btn" onClick={() => {
                this.props.history.push({
                    pathname : '/create_user'
                })
            }}>{t('iv.invite.btn')}</button>
        </Grid>
      </Grid>  
      <Tabs>
          <TabList style={{ textAlign: 'left' }}>
         
         
            <Tab>{t('iv.management')}</Tab>            
            {/* <Tab>Special Report</Tab> */}
          </TabList>

        
          <TabPanel>
           <ManagementUser data={this.props}/>
          </TabPanel>
        </Tabs>        
      </div>
    </div>
    );
  }
}

export default withTranslation()(UserTab);
