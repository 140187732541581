import React, { Component } from 'react';
import { Redirect, NavLink, withRouter } from 'react-router-dom';
import { Navbar, Nav, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import ApiUrlCall from '../Component/API/apiurl';
import constant from '../Component/constant.json';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Config/Navbar.scss';

class NavbarComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigate: false,
      username: localStorage.getItem("username")
    };
    this.logOut = this.logOut.bind(this);
  }

  logOut = () => {
    localStorage.clear();
    this.setState({ navigate: true });
  }

  showOption = () => {
    // Handle showing options if necessary
  }

  openPeopleTracking = (e) => {
    const is_people_tracking = localStorage.getItem('default_view');
    if (is_people_tracking !== constant.people_tracking) {
      ApiUrlCall.ptDefaultView(JSON.stringify({ default_view: 2 })).then(response => {
        if (response.data.response_code === 200 && response.data.is_success) {
          localStorage.setItem('default_view', 2);
          setTimeout(() => {
            window.location.reload(false);
          }, 100);
        }
      });
    } else {
      ApiUrlCall.ptDefaultView(JSON.stringify({ default_view: 1 })).then(response => {
        if (response.data.response_code === 200 && response.data.is_success) {
          localStorage.setItem('default_view', 1);
          setTimeout(() => {
            window.location.reload(false);
          }, 100);
        }
      });
    }
  }

  render() {
    const { navigate, username } = this.state;
    const { t } = this.props;

    if (navigate && localStorage.getItem("token") === null) {
      return <Redirect to="/login" push={true} />;
    }

    return (
      
      <Navbar bg="dark" expand="lg" className="navbar-dark">
        <Navbar.Brand href="#">
          <div className="grid-container">
            <div className="grid-item">
              <img src="images/logo.png" alt="matchpointgps logo" width="235" height="31px" />
            </div>
            <div className="grid-item" style={{ marginLeft: "20px", display: parseInt(localStorage.getItem("group")) !== constant.rent_user ? "" : "none" }}>
              <NavLink tag={NavLink} exact to="/">
                <div className="people_tracking_icon" onClick={this.openPeopleTracking}>
                  <Tooltip style={{ display: localStorage.getItem('default_view') !== constant.people_tracking ? "" : "none" }} title={<span style={{ fontSize: "15px" }}>People Tracking</span>} placement="bottom">
                    <img src="images/people_icon.png" alt="icon"></img>
                  </Tooltip>
                  <Tooltip style={{ display: localStorage.getItem('default_view') === constant.people_tracking ? "" : "none" }} title={<span style={{ fontSize: "15px" }}>Vehicle Tracking</span>} placement="bottom">
                    <img src="images/car_tracking_icon.png" alt="icon"></img>
                  </Tooltip>
                </div>
              </NavLink>
            </div>
          </div>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto nav-links">
            <Nav.Link as={NavLink} exact to="/">{t('os.dashbord')}</Nav.Link>
            <Nav.Link as={NavLink} to="/list_vehicle">{t('ars.sum.v2')}</Nav.Link>
            <Nav.Link as={NavLink} to="/user_list">{t('iv.user')}</Nav.Link>
            <Nav.Link as={NavLink} to="/reports">{t('ss.as.report')}</Nav.Link>
          </Nav>

          <Nav className="ml-auto user-nav">
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id="tooltip-bottom">{username}</Tooltip>}
            >
              <Nav.Link onClick={this.showOption}>
                <img src="images/profile_icon.png" alt="icon"></img>
                {username}
              </Nav.Link>
            </OverlayTrigger>
            <Nav.Link onClick={this.logOut}>{t('ct.logout')}</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default withTranslation()(withRouter(NavbarComponent));
