import React, { Component } from 'react';
import $ from 'jquery';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ApiUrlCall from '../API/apiurl';
import '../reports/reports.scss'

import Navbar from '../../Config/Navbar';
import { withTranslation } from 'react-i18next';
import IMD_reports from './IMD_reports.js';
import Master_dashboard_mumbai from './Master_dashboard_mumbai.js';
import Location_new from './Location_new.js';
class Reports extends Component {
  // _exporter;
  constructor(props) {
    super(props);
    this.state = {      
      
    }
  }

  componentDidMount() {
    setTimeout(() => {
      var navBarHeight = $('.navBar').outerHeight();
      console.log("nav bar height", navBarHeight)
      $('.report_parent').css({
        paddingTop: navBarHeight + 60,
        paddingLeft: "20px",
        paddingRight: "20px"
      })          
    }, 500);
    // console.log("setup function is calling")
  }
  
  render() { 
    const {t} = this.props  
    const user_id = localStorage.getItem('user_id')
    return (
     
     <div>
      <Navbar/>
      <div className="report_parent">

            <div style={{ textAlign: "right",marginBottom : "30px"}}>
            <button className="btn orange-btn" onClick={() => {
            this.props.history.push('/sheduler_report')
                }}>Scheduler Report</button>     
      </div>



     {/* <Tabs  style={{display : localStorage.getItem('group') == 32 ? "" : "none"}}>
        
      <TabList style={{ textAlign: 'left' }}>
          
          <Tab> Master Dashboard</Tab>
      
      </TabList>


          <TabPanel>  
          <IMD_reports></IMD_reports>
          </TabPanel>

          <TabPanel>
          <Master_dashboard_mumbai></Master_dashboard_mumbai>
          </TabPanel>

         
       
          <TabPanel>
          <location_new> </location_new>
          </TabPanel>


        </Tabs> */}
       
       <Tabs  style={{display : localStorage.getItem('group') == 32 ? "" : "none"}}>
        <TabList style={{ textAlign: 'left' }}>  
            <Tab> Master Dashboard</Tab>      
        </TabList>
  
       <TabPanel>  
            <IMD_reports></IMD_reports>
       </TabPanel>
  
          </Tabs>
          
          <Tabs  style={{display : localStorage.getItem('group') == 33 ? "" : "none"}}>
        
           <TabList style={{ textAlign: 'left' }}>
            
           <Tab> Master Dashboard</Tab>
            <Tab> Location</Tab>
            
           </TabList>
 
          <TabPanel>
            <Master_dashboard_mumbai></Master_dashboard_mumbai>
          </TabPanel>
 
         <TabPanel>
           <Location_new> </Location_new>
          </TabPanel> 
          </Tabs>

       
      </div>
 </div>


    );
  }
}

export default withTranslation()(Reports);
