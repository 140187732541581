import React, { Component } from 'react';
import $ from 'jquery';
import { Grid } from '@material-ui/core';
import ApiUrlCall from '../API/apiurl';
import analytics from '../../Config/firebase'
import Amplify, { Analytics } from 'aws-amplify';
import awswebapp from '../../aws-exports';
import constant from '../constant.json';
import { withTranslation } from 'react-i18next';
import Snackbar from '@material-ui/core/Snackbar';


class DashboardSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      layoutId: '',
      widgetId: [],
      widget_id: '',
      layoutData: '',
      dbId: '',
      fleet_id : '',   
      openAlert : false,
      errorMessage : '' , 
    }
    this.layoutData = this.layoutData.bind(this);
  }

  componentDidMount() {
    analytics.logEvent('screen_view',{
      screen_name : "DashboardSetting"
    })
    analytics.logEvent('page_view',{
      page_title : "DashboardSetting",        
    })
    Amplify.configure(awswebapp);
    Analytics.record({
      name: constant.awsName, 
      // Attribute values must be strings
      attributes: { screen_name: 'WEB_DASHBOARD_SETTING'}
    });
    var navBarHeight = $('.navBar').outerHeight();
    var ongoingHeight = $('.ongoingJourney').height();
    var widgetListHeight = $(window).height()
    $('.s_widget_list_parent').css({
      height: widgetListHeight,
    })
    this.layoutData();

  }



  layoutData = (e) => {
    // e.preventDefault();
    // console.log("this fun is callingss")
    ApiUrlCall.getLayout().then(response => {
      // console.log(response)
      this.setState({
        layoutData: response.data,
        layoutId: response.data.layout_id,
        widget_id: response.data.widget_ids,
        dbId: response.data.id,
        fleet_id : response.data.fleet_config,
      }, () => {
        this.dynamicLayoutSelect(this.state.layoutId)
        this.dynamicAppendWidget(this.state.layoutId)
        if(this.state.dbId){
          this.showLayoutDiv("none")
          this.showWidgetDiv("block")
          $('#next_btn').click() 
          $('.user_profile').find('[name='+this.state.fleet_id+']').attr("checked", true)
          $('.user_profile').find('[name='+this.state.fleet_id+']').parent().parent().addClass('s_fleet_highlight')
        }
        // console.log('>>>>>>>>>>>>>>', this.state.fleet_id)
      })
    }).catch(err => {
      console.log(err, 'error while fetching layout data')
    })
  }

  dynamicLayoutSelect = (layout_id) => {
    // console.log('select layout', layout_id)
    var select = document.getElementById(layout_id)
    $(select).attr('checked', 'true')
    // console.log('select layout', $(select).parent())
    $(select).parent().siblings().children().removeClass('s_unselect_layout');
    $(select).siblings().addClass('s_unselect_layout');
  }

  // dynamicAppendWidget = (layout) => {
  //   console.log('layout id is', layout)
  //   var select = document.getElementsByClassName(layout)
  //   var dataWidget = this.state.widget_id.split(",")
  //   var widgets = $(select).find("[name=widget]")
  //   console.log('>>>>>>>>>>>>', typeof widgets[0], '...........', widgets[0])
  //   console.log('<<<<<<<<<<', typeof document.getElementById(dataWidget[0]), '................', document.getElementById(dataWidget[0]))
  //   console.log('widget is', typeof widgets[0].append($('#'+dataWidget[0])))
  //   // widgets[0].appendChild(document.getElementById(dataWidget[0]))
  //   // console.log($(widgets)[0].appendChild('.....'),'..............',dataWidget[0])
  //   // $(widgets)[0].appendChild(dataWidget[0])
  //   for (let i = 0; i < dataWidget.length; i++) {
  //     widgets[i].appendChild(document.getElementById(dataWidget[i]))
  //     // $(widgets)[i].appendChild(element)
  //     $(widgets).eq(i).children().children().first().show()
  //   }
  //   console.log('selected widgets are', $(widgets)[0])


  // }

  dynamicAppendWidget = (layout) => {
    console.log('layout id is', layout)
    var select = document.getElementsByClassName(layout)        
    var dataWidget = this.state.widgetId
    var widgets = $(select).find("[name=widget]")        
    for (let i = 0; i < dataWidget.length; i++) {
        widgets[i].appendChild(document.getElementById(dataWidget[i]))            
        $(widgets).eq(i).children().children().first().show()
    }    
  }

  selectLayout = (e) => {
    e.currentTarget.nextSibling.checked = true;
    var selectedBox = e.currentTarget;
    var layoutId = e.currentTarget.nextSibling.id;
    var fleetId = $('#custom_layout').find('input').prop('name')
    $(selectedBox).parent().siblings().children().removeClass('s_unselect_layout');
    $(selectedBox).addClass('s_unselect_layout');
    this.setState({
      layoutId: layoutId,
      fleet_id : fleetId,
      widgetId : []
    })
  }
  fleetSizeSelection = (e) => {
    const smallFeet = 'small_fleet';
    const mediumFleet = 'medium_fleet';
    const largeFleet = 'large_fleet';
    const mapOnly = 'map_only';
    const customLayout = 'custom_layout';
    var selectedOption = e.currentTarget.id; 
    var fleetId = $(e.currentTarget).find('input').prop('name') 
    setTimeout(() => {
        var halfWidget = $('.ld_1').find('.w_notification').outerHeight() / 2
        // console.log('heigh is', halfWidget)
        $('.s_w_content_div').css({
            height: halfWidget - 5
        })
        $('.s_vh_content_div').css({
            height: halfWidget - 5
        })
    }, 300)  
    $(e.currentTarget.parentNode).siblings().children().find('input').prop("checked", false)
    e.currentTarget.firstChild.firstChild.checked = true
    // console.log('input')
    $(e.currentTarget).addClass('s_fleet_highlight');
    $(e.currentTarget.parentNode).siblings().children().removeClass('s_fleet_highlight');
    $('.s_widget_list').appendTo('.s_widget_list_parent')
    $('.s_remove_icon').hide()
    switch (selectedOption) {
      case smallFeet:
        this.showLayoutDiv("none");
        this.showWidgetDiv("block");
        $('.ld_1').show();
        $('.ld_1').siblings().hide();
        $('#back_btn').show();
        $('#next_btn').hide();
        $('#update_btn').show()
        this.setState({
          layoutId: "ld_1",
          fleet_id : fleetId,
          widget_id : "FleetSummary,MapView,OverallVehicleHealth,VehicleDocument"
        },() => {
          this.dynamicAppendWidget("ld_1")
        })
        break;
      case mediumFleet:
        this.showLayoutDiv("none")
        this.showWidgetDiv("block")
        $('.ld_1').show()
        $('.ld_1').siblings().hide()
        $('#back_btn').show()
        $('#next_btn').hide()
        $('#update_btn').show()
        this.setState({
          layoutId: "ld_1",
          fleet_id : fleetId,
          widget_id : "FleetSummary,VehicleUsage,OverallVehicleHealth,TodaysJourney"
        },() => {
          this.dynamicAppendWidget("ld_1")
        })
        break;
      case largeFleet:
        this.showLayoutDiv("none")
        this.showWidgetDiv("block")
        $('.ld_1').show()
        $('.ld_1').siblings().hide()
        $('#back_btn').show()
        $('#next_btn').hide()
        $('#update_btn').show()
        this.setState({
          layoutId: "ld_1",
          fleet_id : fleetId,
          widget_id : "FleetSummary,TodaysJourney,VehicleUsage,DriverEfficiency"
        },() => {
          this.dynamicAppendWidget("ld_1")
        })
        break;
      case mapOnly:
        this.showLayoutDiv("none")
        this.showWidgetDiv("block")
        $('.ld_6').show()
        $('.ld_6').siblings().hide()
        $('#back_btn').show()
        $('#next_btn').hide()
        $('#update_btn').show()
        this.setState({
          layoutId: "ld_6",
          fleet_id : fleetId,
          widget_id : "MapView"
        },() => {
          this.dynamicAppendWidget("ld_6")
        })
        break;
      case customLayout:
        this.showLayoutDiv("block")
        this.showWidgetDiv("none")
        $('#back_btn').hide()
        $('#next_btn').show()
        $('#update_btn').hide()
        this.setState({          
          fleet_id : fleetId,
          widgetId : []
        })
        break;
      default:
        this.showLayoutDiv("block")
        this.showWidgetDiv("none")
        $('#back_btn').hide()
        $('#next_btn').show()
        $('#update_btn').show()
        break;
    }

  }

  showLayoutDiv = (visibility) => {
    document.getElementById('layout_parent').style.display = visibility
    if (visibility == "block") {
      $('#layout_btn').removeClass('inactive_view_btn')
      $('#layout_btn').addClass('active_view_btn')
      $('#widget_btn').addClass('inactive_view_btn')
      $('#widget_btn').removeClass('active_view_btn')
    }
  }

  showWidgetDiv = (visibility) => {
    document.getElementById('s_widget_setting_parent').style.display = visibility
    if (visibility == "block") {
      $('#widget_btn').removeClass('inactive_view_btn')
      $('#widget_btn').addClass('active_view_btn')
      $('#layout_btn').addClass('inactive_view_btn')
      $('#layout_btn').removeClass('active_view_btn')
    }
  }
  nextToWidget = (e) => {
    var clickedLayout = this.state.layoutId;
    if (clickedLayout) {
      setTimeout(() => {
        var halfWidget = $('.'+clickedLayout).find('.w_notification').outerHeight() / 2     
        // console.log('heigh is', halfWidget)   
        $('.s_w_content_div').css({
          height: halfWidget - 5
        })
        $('.s_vh_content_div').css({
          height: halfWidget - 5
        })        
      },200)
      this.showLayoutDiv("none")
      this.showWidgetDiv("block")
      $(e.currentTarget).siblings().show()
      $(e.currentTarget).hide()
      $('.' + clickedLayout).show()
      $('.' + clickedLayout).siblings().hide()
    }
  }

  backToLayout = (e) => {
    // console.log(">>>>>> layout view");
    this.showLayoutDiv("block")
    this.showWidgetDiv("none")
    $('#next_btn').show()
    $('#update_btn').hide()
    $(e.currentTarget).hide()
    $('#custom_layout').addClass('s_fleet_highlight')
    $('#custom_layout').parent().siblings().children().removeClass('s_fleet_highlight')
    $('#custom_layout').find('input').prop("checked", true)
    $('.s_widget_list').appendTo('.s_widget_list_parent')
    $('.s_widget_list').find('.s_remove_icon').hide()
    this.state.widgetId.length = 0
    this.forceUpdate()
  }

  okClick = () => {
    $('#processing').show()
    const selectedLayout = this.state.layoutId
    // console.log('selected layout is', selectedLayout)
    var widgetLayout = document.getElementsByClassName(selectedLayout)
    var widgetbody = $(widgetLayout).find('.s_w_content_div, .s_h_content_div, .s_vh_content_div').length
    var selectedWidget = $(widgetLayout).find('.s_w_content_div, .s_h_content_div, .s_vh_content_div').children()
    // console.log(selectedWidget, 'manish >>>>>>>>>>', widgetbody);
    if (widgetbody === selectedWidget.length) {
      // console.log('in if statement')
      for (let i = 0; i < selectedWidget.length; i++) {
        const element = selectedWidget[i];
        const widgetid = $(element).prop('id')
        this.state.widgetId.push(widgetid)
        console.log('state widgets', this.state.widgetId)
        this.forceUpdate()
      }
    } else {
      $('#processing').hide()
      this.setState({
        openAlert : true,
        errorMessage : "Please select widgets as per selected layout design"
      })
      return
      // console.log('in else statement')
    }

    if (this.state.widgetId) {
      const sendLayoutData = {fleet_config: this.state.fleet_id, id: this.state.dbId, layout_id: this.state.layoutId, widget_ids: String(this.state.widgetId) }
      const layoutJsonData = JSON.stringify(sendLayoutData)
      console.log('layout data is', layoutJsonData)      
      var layoutWidget = this.state.widgetId
      ApiUrlCall.updateLayout(layoutJsonData).then(response => {
        // console.log('post data', response);
        $('#processing').hide()
        window.location.reload(false);
      }).catch(error => {
        $('#processing').hide()
        if (layoutWidget) {
          layoutWidget.length = 0;
          // console.log('post data >>>>>>>>>>>', this.state.widgetId);
        }
        console.log('post data', error.response);
      })
      // console.log('json data', layoutJsonData)
    }
  }

  // Drag and drop functionality start
  widgetDragOver = (ev) => {
    ev.preventDefault();
  }
  widgetDragEnd = (ev) => {
    // console.log('drag over endddddd', ev.target);
  }
  widgetDragStart = (ev) => {
    // console.log('what is ev', ev)
    var dragstart = ev.dataTransfer.setData("text", ev.target.id);
    // console.log('drag start', dragstart)
  }

  widgetDropDiv = (ev) => {
    ev.preventDefault();
    var data = ev.dataTransfer.getData("text");
    // console.log('what is e in drop', typeof ev.target, '------', typeof data)
    var appendDiv = document.getElementById(data)
    // console.log(typeof appendDiv, 'apeend in diev')
    ev.target.appendChild(appendDiv);
    $(ev.target).children().children().first().show()
  }

  removeWidget = (e) => {
    $(e.currentTarget.parentNode).appendTo('.s_widget_list_parent')
    $(e.currentTarget).hide()
    // console.log('widget data', this.state.widgetId)
  }


  render() {
    const {t} = this.props
    return (
      <div className="dash_setting_parent">
        {/* div with empty journey start */}
        <div className="user_profile">
          <Grid container>
            <Grid item xs>
              <div className="s_fs_checkbox_parent" onClick={this.fleetSizeSelection} id="small_fleet">
                <div style={{ display: "table-cell" }}>
                  <input type="radio" name="1"></input>
                </div>
                <div style={{ display: "table-cell" }}>
                  <p>{t('os.small')}</p>
                  <p><b>1-10 {t('os.vehicles')}</b></p>
                </div>
              </div>
            </Grid>
            <Grid item xs>
              <div className="s_fs_checkbox_parent" onClick={this.fleetSizeSelection} id="medium_fleet">
                <div style={{ display: "table-cell" }}>
                  <input type="radio" name="2"></input>
                </div>
                <div style={{ display: "table-cell" }}>
                  <p>{t('os.medium')}</p>
                  <p><b>11-50 {t('os.vehicles')}</b></p>
                </div>
              </div>
            </Grid>
            <Grid item xs>
              <div className="s_fs_checkbox_parent" onClick={this.fleetSizeSelection} id="large_fleet">
                <div style={{ display: "table-cell" }}>
                  <input type="radio" name="3"></input>
                </div>
                <div style={{ display: "table-cell" }}>
                  <p>{t('os.large')}</p>
                  <p><b>more than 50 {t('os.vehicles')}</b></p>
                </div>
              </div>
            </Grid>
            <Grid item xs>
              <div className="s_fs_checkbox_parent" onClick={this.fleetSizeSelection} id="map_only">
                <div style={{ display: "table-cell" }}>
                  <input type="radio" name="4"></input>
                </div>
                <div style={{ display: "table-cell" }}>
                  <p><b>{t('os.map')}</b></p>
                </div>
              </div>
            </Grid>
            <Grid item xs>
              <div className="s_fs_checkbox_parent" onClick={this.fleetSizeSelection} id="custom_layout">
                <div style={{ display: "table-cell" }}>
                  <input type="radio" name="5"></input>
                </div>
                <div style={{ display: "table-cell" }}>
                  <p><b>{t('os.custom')}</b></p>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        {/* div with empty journey end */}

        <div className="" style={{ marginTop: "30px" }}>
          <div>
            <div className="graph_view_btn" style={{ display: "inline-block" }}>
              <div className="active_view_btn" style={{ display: "inline-block" }} onClick={this.dataViewClick} id="layout_btn">
                <label>{t('os.layout')}</label>
              </div>
              <div className="inactive_view_btn" style={{ display: "inline-block" }} onClick={this.dataViewClick} id="widget_btn">
                <label>{t('os.widget')}</label>
              </div>
            </div>
          </div>

          {/* layout view start */}
          <div style={{ marginTop: "50px", display: "block" }} id="layout_parent">
            <Grid spacing={5} container>
              <Grid item xs={4}>
                <div className="s_select_layout" onClick={this.selectLayout}>
                  <img src="images/01.png"></img>
                </div>
                <input type="radio" className="s_isHidden" name="layout" id="ld_1"></input>
              </Grid>
              <Grid item xs={4}>
                <div className="s_select_layout" onClick={this.selectLayout}>
                  <img src="images/02.png"></img>
                </div>
                <input type="radio" className="s_isHidden" name="layout" id="ld_2"></input>
              </Grid>
              <Grid item xs={4}>
                <div className="s_select_layout" onClick={this.selectLayout}>
                  <img src="images/03.png"></img>
                </div>
                <input type="radio" className="s_isHidden" name="layout" id="ld_3"></input>
              </Grid>
              <Grid item xs={4}>
                <div className="s_select_layout" onClick={this.selectLayout}>
                  <img src="images/04.png"></img>
                </div>
                <input type="radio" className="s_isHidden" name="layout" id="ld_4"></input>
              </Grid>
              <Grid item xs={4}>
                <div className="s_select_layout" onClick={this.selectLayout}>
                  <img src="images/05.png"></img>
                </div>
                <input type="radio" className="s_isHidden" name="layout" id="ld_5"></input>
              </Grid>
              <Grid item xs={4}>
                <div className="s_select_layout" onClick={this.selectLayout}>
                  <img src="images/06.png"></img>
                </div>
                <input type="radio" className="s_isHidden" name="layout" id="ld_6"></input>
              </Grid>
            </Grid>
          </div>
          {/* layout view end */}

          {/* widget view start */}
          <div style={{ marginTop: "50px", display: "none" }} id="s_widget_setting_parent">
            <Grid spacing={3} container>
              <Grid item xs={8}>
                {/* layout 1 start */}
                <div className="s_widget_setting_container ld_1" style={{ display: "none" }}>
                  <Grid spacing={3} container>
                    <Grid item xs={12}>
                      <div className="s_w_ongoing"></div>
                    </Grid>
                  </Grid>
                  <Grid className="add_widget" spacing={1} container>
                    <Grid item xs spacing={1} container>
                      <Grid item xs={6}>
                        <div className="s_w_content_div" name="widget" onDrop={this.widgetDropDiv} onDragOver={this.widgetDragOver}></div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="s_w_content_div" name="widget" onDrop={this.widgetDropDiv} onDragOver={this.widgetDragOver}></div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="s_w_content_div" name="widget" onDrop={this.widgetDropDiv} onDragOver={this.widgetDragOver}></div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="s_w_content_div" name="widget" onDrop={this.widgetDropDiv} onDragOver={this.widgetDragOver}></div>
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      <div className="w_notification"></div>
                    </Grid>
                  </Grid>
                </div>
                {/* layout 1 end */}
                {/* layout 2 start */}
                <div className="s_widget_setting_container ld_2" style={{ display: "none" }}>
                  <Grid spacing={3} container>
                    <Grid item xs={12}>
                      <div className="s_w_ongoing"></div>
                    </Grid>
                  </Grid>
                  <Grid className="add_widget" spacing={1} container>
                    <Grid item xs spacing={1} container>
                      <Grid item xs={6}>
                        <div className="s_h_content_div" name="widget" onDrop={this.widgetDropDiv} onDragOver={this.widgetDragOver}></div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="s_vh_content_div" name="widget" style={{ marginBottom: "0.5em" }} onDrop={this.widgetDropDiv} onDragOver={this.widgetDragOver}></div>
                        <div className="s_vh_content_div" name="widget" onDrop={this.widgetDropDiv} onDragOver={this.widgetDragOver}></div>
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      <div className="w_notification"></div>
                    </Grid>
                  </Grid>
                </div>
                {/* layout 2 end */}
                {/* layout 3 start */}
                <div className="s_widget_setting_container ld_3" style={{ display: "none" }}>
                  <Grid spacing={3} container>
                    <Grid item xs={12}>
                      <div className="s_w_ongoing"></div>
                    </Grid>
                  </Grid>
                  <Grid className="add_widget" spacing={1} container>
                    <Grid item xs spacing={1} container>
                      <Grid item xs={6}>
                        <div className="s_h_content_div" name="widget" onDrop={this.widgetDropDiv} onDragOver={this.widgetDragOver}></div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="s_h_content_div" name="widget" onDrop={this.widgetDropDiv} onDragOver={this.widgetDragOver}></div>
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      <div className="w_notification"></div>
                    </Grid>
                  </Grid>
                </div>
                {/* layout 3 end */}
                {/* layout 4 start */}
                <div className="s_widget_setting_container ld_4" style={{ display: "none" }}>
                  <Grid spacing={3} container>
                    <Grid item xs={12}>
                      <div className="s_w_ongoing"></div>
                    </Grid>
                  </Grid>
                  <Grid className="add_widget" spacing={1} container>
                    <Grid item xs spacing={1} container>
                      <Grid item xs={12}>
                        <div className="s_w_content_div" name="widget" onDrop={this.widgetDropDiv} onDragOver={this.widgetDragOver}></div>
                      </Grid>
                      <Grid item xs={12}>
                        <div className="s_w_content_div" name="widget" onDrop={this.widgetDropDiv} onDragOver={this.widgetDragOver}></div>
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      <div className="w_notification"></div>
                    </Grid>
                  </Grid>
                </div>
                {/* layout 4 end */}
                {/* layout 5 start */}
                <div className="s_widget_setting_container ld_5" style={{ display: "none" }}>
                  <Grid spacing={3} container>
                    <Grid item xs={12}>
                      <div className="s_w_ongoing"></div>
                    </Grid>
                  </Grid>
                  <Grid className="add_widget" spacing={1} container>
                    <Grid item xs spacing={1} container>
                      <Grid item xs={12}>
                        <div className="s_w_content_div" name="widget" onDrop={this.widgetDropDiv} onDragOver={this.widgetDragOver}></div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="s_w_content_div" name="widget" onDrop={this.widgetDropDiv} onDragOver={this.widgetDragOver}></div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="s_w_content_div" name="widget" onDrop={this.widgetDropDiv} onDragOver={this.widgetDragOver}></div>
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      <div className="w_notification"></div>
                    </Grid>
                  </Grid>
                </div>
                {/* layout 5 end */}
                {/* layout 6 start */}
                <div className="s_widget_setting_container ld_6" style={{ display: "none" }}>
                  <Grid spacing={3} container>
                    <Grid item xs={12}>
                      <div className="s_w_ongoing"></div>
                    </Grid>
                  </Grid>
                  <Grid className="add_widget" spacing={1} container>
                    <Grid item xs spacing={1} container>
                      <Grid item xs={12}>
                        <div className="s_h_content_div" name="widget" onDrop={this.widgetDropDiv} onDragOver={this.widgetDragOver}></div>
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      <div className="w_notification"></div>
                    </Grid>
                  </Grid>
                </div>
                {/* layout 6 end */}
              </Grid>
              <Grid item xs={4}>
                <div className="s_widget_list_parent">
                  <div id="FleetSummary" className="s_widget_list" draggable="true" onDragStart={this.widgetDragStart}>
                    <div className="s_remove_icon" onClick={this.removeWidget}><i className="fa fa-close" aria-hidden="true"></i></div>
                    <img src="images/Fleet Summary.png" draggable="false"></img>
                  </div>
    
                  <div id="MapView" className="s_widget_list" draggable="true" onDragStart={this.widgetDragStart}>
                    <div className="s_remove_icon" onClick={this.removeWidget}><i className="fa fa-close" aria-hidden="true"></i></div>
                    <img src="images/Map View.png" draggable="false"></img>
                  </div>
                  
                </div>
              </Grid>
            </Grid>
          </div>
          {/* widget view end */}
          <div className="s_layout_btn">
            <img src="images/layout_next.png" alt="icon" className="s_back_btn" style={{ display: "none" }} onClick={this.backToLayout} id="back_btn"></img>
            <img src="images/layout_next.png" alt="icon" onClick={this.nextToWidget} id="next_btn"></img>
            <img src="images/layout_save.png" alt="icon" width="74px" onClick={this.okClick} style={{ display: "none" }} id="update_btn"></img>
          </div>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          autoHideDuration={2000}
          onClose={(e, reason) => {
            if (reason === 'clickaway') {
              return;
            }
            this.setState({ openAlert: false })
          }
          }
          open={this.state.openAlert}
          message={this.state.errorMessage}
        />
      </div>
    );
  }
}

export default withTranslation()(DashboardSetting);
