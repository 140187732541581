import React, { Component } from 'react';
import $ from 'jquery';
import './login.scss';
import AuthService from '../src/Component/Login/Authservice';
import { Redirect } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import { Grid } from '@material-ui/core';
import analytics from './Config/firebase';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: true,
      showOtp: false,
      username: '',
      password: '',
      message: '',
      mobile_number: '',
      otp: '',
      step: 'init',
      items: [],
      isLoaded: false,
      redirectToReferrer: false,
      token: '',
      counter: 0,
      clicks: 0,
      showgetOtp: false,
      showreSendOtp: false,
      showloginWithOtp: false,
      showlogin: true,
      openAlert: false,
      errorMessage: '',
      enterInput: 'Registered Email or Mobile number',
      demoClick: false,
      validateMobile: true,
    };

    this.login = this.login.bind(this);
    this.loginWithOtp = this.loginWithOtp.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.increment = this.increment.bind(this);
  }

  increment() {
    this.setState({ counter: this.state.counter + 1 });
  }

  componentWillUnmount() {
    window.location.reload(false);
  }

  componentDidMount() {
    $('.fixed-icon').hide();
    analytics.logEvent('screen_view', { screen_name: 'Login' });
    const loginHeight = $(window).outerHeight();
    $('.login_parent');
  }
  
  showSuggestion(div) {
    if (div.value) {
      $(div).prev().slideDown(400);
    } else {
      $(div).prev().fadeOut(300);
    }
  }

  handleChange(e) {
    this.showSuggestion(e.currentTarget);
    if (/^\d+$/.test(e.target.value)) {
      $('#password_input').val('');
      $('#password_input').prev().hide();
      this.setState({
        showPassword: false,
        mobile_number: e.target.value,
        showgetOtp: true,
        showlogin: false,
        showloginWithOtp: false,
        showreSendOtp: false,
      });
    } else {
      $('#otp_input').val('');
      $('#otp_input').prev().hide();
      if (this.state.demoClick) {
        this.setState({ showlogin: false });
      } else {
        this.setState({
          showPassword: true,
          username: e.target.value,
          showgetOtp: false,
          showlogin: true,
          showloginWithOtp: false,
          showreSendOtp: false,
        });
      }
    }
    this.setState({ password: e.target.value });
  }

  getOtp(e) {
    if (this.state.validateMobile) {
      e.preventDefault();
      $('#resend_otp').css({ pointerEvents: 'none', color: '#505050' });
      const credentials = { mobile_number: this.state.mobile_number };
      AuthService.generateOtp(credentials)
        .then((res) => {
          if (res.data.response_code === 200 && res.data.is_success) {
            this.setState({
              showgetOtp: false,
              showreSendOtp: true,
              showlogin: false,
              showOtp: true,
              step: 'otp',
            }, () => {
              $('#email_mobile').prop('disabled', true);
            });
          } else {
            this.setState({ openAlert: true, errorMessage: res.data.response_message });
          }
        })
        .catch((err) => {
          this.setState({
            showgetOtp: true,
            showreSendOtp: false,
            showlogin: false,
            showOtp: false,
          });
          console.error(err, 'error in getting otp');
        });
      setTimeout(() => {
        $('#resend_otp').css({ pointerEvents: 'auto', color: '#f0762b' });
      }, 30000);
    } else {
      this.setState({ openAlert: true, errorMessage: 'Enter a valid number' });
    }
  }

  reSendOtp(e) {
    e.preventDefault();
    const credentials = { mobile_number: this.state.mobile_number };
    AuthService.generateOtp(credentials)
      .then((res) => {
        if (res.data.response_code === 200 && res.data.is_success) {
          // Handle success
        }
      })
      .catch((err) => console.error(err, 'error while resending OTP'));
  }

  loginWithOtp(e) {
    e.preventDefault();
    const credentials = { 
      mobile_number: this.state.mobile_number, 
      otp: this.state.otp, 
      is_demo: this.state.demoClick 
    };
    AuthService.login(credentials).then((res) => {
      if (res.data.response_code === 200 && res.data.is_success) {
        localStorage.setItem('token', res.data.data.token);
        localStorage.setItem('user_id', res.data.data.user);
        localStorage.setItem('username', res.data.data.customer_name);
        localStorage.setItem('user_name', res.data.data.username);
        localStorage.setItem('is_endpoint', false);
        localStorage.setItem('group', res.data.data.group_id);
        localStorage.setItem('is_demo', res.data.data.is_demo);
        localStorage.setItem('default_view', res.data.data.default_view);
        this.setState({ redirectToReferrer: true });
        window.location.reload(false);
      } else {
        this.setState({ message: res.data.response_message, openAlert: true, errorMessage: res.data.response_message });
        this.setState({ counter: this.state.counter + 1 });
        if (this.state.counter > 3) {
          this.setState({ openAlert: true, errorMessage: 'You have exceeded number of attempts. Please contact our Customer Care.' });
        }
      }
    });
  }

  login(e) {
    e.preventDefault();
    const credentials = { username: this.state.username, password: this.state.password };
    AuthService.login(credentials).then((res) => {
      if (res.data.response_code === 200) {
        localStorage.setItem('token', res.data.data.token);
        localStorage.setItem('username', res.data.data.customer_name);
        localStorage.setItem('user_id', res.data.data.user);
        localStorage.setItem('user_name', res.data.data.username);
        localStorage.setItem('is_endpoint', false);
        localStorage.setItem('group', res.data.data.group_id);
        localStorage.setItem('default_view', res.data.data.default_view);
        this.setState({ redirectToReferrer: true });
        window.location.reload(false);
      } else {
        this.setState({ openAlert: true, errorMessage: res.data.response_message });
        this.setState({ counter: this.state.counter + 1 });
        if (this.state.counter > 3) {
          this.setState({ openAlert: true, errorMessage: 'You have exceeded number of attempts. Please contact our Customer Care.' });
        }
      }
    });
  }

  otpValue(e) {
    this.showSuggestion(e.currentTarget);
    this.setState({ otp: e.target.value });
    if (e.target.value.length <= 6) {
      this.setState({ showgetOtp: false, showlogin: false, showloginWithOtp: true });
    } else {
      this.setState({ openAlert: true, errorMessage: 'OTP entered is more than 6 digits. Enter Valid OTP' });
    }
  }

  passwordValue(e) {
    this.showSuggestion(e.currentTarget);
    this.setState({ password: e.target.value, showgetOtp: false, showreSendOtp: false, showlogin: true, showloginWithOtp: false });
  }

  demoLogin(e) {
    $(e.currentTarget).hide();
    $('#email_mobile').val('');
    $('#email_mobile').prop('type', 'number');
    this.setState({ showgetOtp: true, showlogin: false, showPassword: false, demoClick: true, enterInput: 'Enter Mobile number', mobile_number: '' });
  }

  mobileNumberValidation(e) {
    if (this.state.showgetOtp) {
      this.setState({ validateMobile: e.currentTarget.value.length === 10 });
    }
  }

  render() {
    const { from } = this.props.location.state || { from: { pathname: '/' } };
    const { redirectToReferrer } = this.state;

    if (redirectToReferrer === true) {
      return <Redirect to={from} />;
    }

    return (
      <div className="login_parent">
        <Grid container  >
          <Grid  className='logos' >
            <img src="images/logo.png" alt="icon" className="logo" />
          </Grid>
          <Grid >
            <p className="cj_w_header customer_care">
              Need help? <span style={{ color: '#F0762B' }}>
                <i className="fa fa-volume-control-phone" aria-hidden="true"></i> 9029070083 (24x7)
              </span>
            </p>
          </Grid>
        </Grid>
        <div className="login_details_div">
          <p className="login_header">Login ID</p>
          <span className="textbox_suggestion">{this.state.enterInput}</span>

          <div>
            
          </div>
          <input
            type="text"
            id="email_mobile"
            autoComplete="off"
            className="form-control"
            onKeyUp={this.mobileNumberValidation.bind(this)}
            onChange={this.handleChange}
            name="email_mobile"
            placeholder={this.state.enterInput}
          />
          <span style={{ display: !this.state.demoClick ? '' : 'none' }} className="textbox_suggestion">Password</span>
          <input
            id="password_input"
            type="password"
            autoComplete="off"
            className="form-control"
            placeholder="Password"
            style={{ display: this.state.showPassword && !this.state.demoClick ? '' : 'none' }}
            onChange={this.passwordValue.bind(this)}
            onKeyUp={(e) => {
              if (e.keyCode === 13) {
                $('#username_login').click();
              }
            }}
          />
          <img
            src="images/show_password.png"
            className="show_password"
            alt="icon"
            style={{ display: 'none' }}
            onClick={(e) => {
              $('#password_input').attr('type', 'password');
              $(e.currentTarget).next().show();
              $(e.currentTarget).hide();
            }}
          />
          <img
            src="images/view_password.png"
            className="show_password"
            style={{ display: this.state.showPassword && !this.state.demoClick ? '' : 'none' }}
            alt="icon"
            onClick={(e) => {
              $('#password_input').attr('type', 'text');
              $(e.currentTarget).prev().show();
              $(e.currentTarget).hide();
            }}
          />
          <span className="textbox_suggestion">OTP</span>
          <input
            id="otp_input"
            type="number"
            autoComplete="off"
            className="form-control"
            placeholder="Enter OTP"
            style={{ display: this.state.showOtp ? '' : 'none' }}
            onChange={this.otpValue.bind(this)}
            onKeyUp={(e) => {
              if (e.keyCode === 13) {
                $('#mobile_login').click();
              }
            }}
          />

          <label className="switch_to" style={{ display: this.state.showreSendOtp ? '' : 'none' }} onClick={this.reSendOtp.bind(this)} id="resend_otp">
            Resend OTP
          </label>

          <button id="username_login" className="btn orange-btn sign_in_btn" style={{ display: this.state.showlogin ? '' : 'none' }} onClick={this.login}>
            Sign in
          </button>
          <button className="btn orange-btn sign_in_btn" style={{ display: this.state.showgetOtp ? '' : 'none' }} onClick={this.getOtp.bind(this)}>
            Get OTP
          </button>
          <button id="mobile_login" className="btn orange-btn sign_in_btn" style={{ display: this.state.showOtp ? '' : 'none' }} onClick={this.loginWithOtp}>
            Sign in
          </button>
          <p className="p_header v_start_journey" style={{ textAlign: 'center' }} onClick={this.demoLogin.bind(this)}>
            Login As Demo User
          </p>
        </div>
        <img src="images/login_bg.png" alt="icon" className="login_bg_img" />
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={4000}
          onClose={(e, reason) => {
            if (reason === 'clickaway') {
              return;
            }
            this.setState({ openAlert: false });
          }}
          open={this.state.openAlert}
          message={this.state.errorMessage}
        />
      </div>
    );
  }
}

export default Login;
