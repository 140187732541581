import React, { Component } from 'react'
import $ from 'jquery';
import '../dashboard/dashboard.scss'
import './onboarding.scss'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Axios from 'axios';
import AuthService from '../Login/Authservice';
import ApiUrlCall from '../API/apiurl';
import { List } from '@material-ui/core';
import Navbar from '../../Config/Navbar';
import analytics from '../../Config/firebase'
import Amplify, { Analytics } from 'aws-amplify';
import awswebapp from '../../aws-exports';
import constant from '../constant.json';
import { withTranslation } from 'react-i18next';
import Snackbar from '@material-ui/core/Snackbar';


class DashboardSetup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            layoutId: '',
            widgetId: [],
            fleet_id : '',
            openAlert : false,
            errorMessage : '' ,
        }
        //  this.layoutData = this.layoutData.bind(this);
    }

    componentDidMount() {
        analytics.logEvent('screen_view',{
            screen_name : "Onboarding"
          }) 
          analytics.logEvent('page_view',{
            page_title : "Onboarding",        
          })
        Amplify.configure(awswebapp);
        Analytics.record({
        name: constant.awsName, 
        // Attribute values must be strings
        attributes: { screen_name: 'WEB_ONBOARDING'}
        });
        var navBarHeight = $('.navBar').outerHeight();
        var ongoingHeight = $('.ongoingJourney').height();
        var widgetListHeight = $(window).height();
        $('.widget_list_parent').css({
            height: widgetListHeight,
        })
        $('.ongoingJourney').css({
            paddingTop: navBarHeight + 60,
        })        

    }    

    selectLayout = (e) => {
        $('#custom_layout').find('input').prop('checked', true)
        $('#custom_layout').addClass('fleet_highlight')
        var fleetId = $('#custom_layout').find('input').prop('name')
        // console.log('fleet id', fleetId)
        e.currentTarget.nextSibling.checked = true;
        var selectedBox = e.currentTarget;
        var layoutId = e.currentTarget.nextSibling.id;
        $(selectedBox).parent().siblings().children().removeClass('unselect_layout');
        $(selectedBox).addClass('unselect_layout');
        this.setState({
            layoutId: layoutId,
            fleet_id : fleetId
        })
    }
    dynamicAppendWidget = (layout) => {
        // console.log('layout id is', layout)
        var select = document.getElementsByClassName(layout)        
        var dataWidget = this.state.widgetId
        var widgets = $(select).find("[name=widget]")        
        for (let i = 0; i < dataWidget.length; i++) {
            widgets[i].appendChild(document.getElementById(dataWidget[i]))            
            $(widgets).eq(i).children().children().first().show()
        }    
      }
    fleetSizeSelection = (e) => {
        const smallFeet = 'small_fleet';
        const mediumFleet = 'medium_fleet';
        const largeFleet = 'large_fleet';
        const mapOnly = 'map_only';
        const customLayout = 'custom_layout';
        var selectedOption = e.currentTarget.id;
        var fleetId = $(e.currentTarget).find('input').prop('name')
        // console.log('fleet id', fleetId)
        setTimeout(() => {
            var halfWidget = $('.ld_1').find('.w_notification').outerHeight() / 2
            // console.log('heigh is', halfWidget)
            $('.w_content_div').css({
                height: halfWidget - 5
            })
            $('.vh_content_div').css({
                height: halfWidget - 5
            })
        }, 200)
        $(e.currentTarget.parentNode).siblings().children().find('input').prop("checked", false)
        e.currentTarget.firstChild.firstChild.checked = true;
        $(e.currentTarget).addClass('fleet_highlight');
        $(e.currentTarget.parentNode).siblings().children().removeClass('fleet_highlight');
        $('.widget_list').appendTo('.widget_list_parent')
        $('.remove_icon').hide()
        switch (selectedOption) {
            case smallFeet:
                this.showLayoutDiv("none");
                this.showWidgetDiv("block");
                $('.ld_1').show();
                $('.ld_1').siblings().hide();
                $('#back_btn').show();
                $('#next_btn').hide();
                $('#update_btn').show()
                this.setState({
                    layoutId: "ld_1",
                    fleet_id : fleetId,
                    widgetId : ["FleetSummary","MapView","OverallVehicleHealth","VehicleDocument"]
                }, () => {
                    this.dynamicAppendWidget("ld_1")  
                })
                break;
            case mediumFleet:
                this.showLayoutDiv("none")
                this.showWidgetDiv("block")
                $('.ld_1').show()
                $('.ld_1').siblings().hide()
                $('#back_btn').show()
                $('#next_btn').hide()
                $('#update_btn').show()
                this.setState({
                    layoutId: "ld_1",
                    fleet_id : fleetId,
                    widgetId : ["FleetSummary","VehicleUsage","OverallVehicleHealth","TodaysJourney"]
                }, () => {
                    this.dynamicAppendWidget("ld_1")  
                })
                break;
            case largeFleet:
                this.showLayoutDiv("none")
                this.showWidgetDiv("block")
                $('.ld_1').show()
                $('.ld_1').siblings().hide()
                $('#back_btn').show()
                $('#next_btn').hide()
                $('#update_btn').show()
                this.setState({
                    layoutId: "ld_1",
                    fleet_id : fleetId,
                    widgetId : ["FleetSummary","TodaysJourney","VehicleUsage","DriverEfficiency"]
                }, () => {
                    this.dynamicAppendWidget("ld_1")  
                })
                break;
            case mapOnly:
                this.showLayoutDiv("none")
                this.showWidgetDiv("block")
                $('.ld_6').show()
                $('.ld_6').siblings().hide()
                $('#back_btn').show()
                $('#next_btn').hide()
                $('#update_btn').show()
                this.setState({
                    layoutId: "ld_6",
                    fleet_id : fleetId,
                    widgetId : ["MapView"]
                }, () => {                    
                    this.dynamicAppendWidget("ld_6")                                            
                })
                break;
            case customLayout:
                this.showLayoutDiv("block")
                this.showWidgetDiv("none")
                $('#back_btn').hide()
                $('#next_btn').show()
                $('#update_btn').hide()
                this.setState({                    
                    fleet_id : fleetId,
                    widgetId : []
                }, () => {
                    console.log('widget ids', this.state.widgetId)
                })
                break;
            default:
                this.showLayoutDiv("block")
                this.showWidgetDiv("none")
                $('#back_btn').hide()
                $('#next_btn').show()
                $('#update_btn').hide()
                break;
        }

    }

    showLayoutDiv = (visibility) => {
        document.getElementById('layout_parent').style.display = visibility
        if (visibility == "block") {
            $('#layout_btn').removeClass('inactive_view_btn')
            $('#layout_btn').addClass('active_view_btn')
            $('#widget_btn').addClass('inactive_view_btn')
            $('#widget_btn').removeClass('active_view_btn')
        }
    }

    showWidgetDiv = (visibility) => {
        document.getElementById('widget_setting_parent').style.display = visibility
        if (visibility == "block") {
            $('#widget_btn').removeClass('inactive_view_btn')
            $('#widget_btn').addClass('active_view_btn')
            $('#layout_btn').addClass('inactive_view_btn')
            $('#layout_btn').removeClass('active_view_btn')
        }
    }
    nextToWidget = (e) => {
        var clickedLayout = this.state.layoutId;
        if (clickedLayout) {
            setTimeout(() => {
                var halfWidget = $('.' + clickedLayout).find('.w_notification').outerHeight() / 2
                // console.log('heigh is', halfWidget)
                $('.w_content_div').css({
                    height: halfWidget - 5
                })
                $('.vh_content_div').css({
                    height: halfWidget - 5
                })
            }, 200)
            this.showLayoutDiv("none")
            this.showWidgetDiv("block")
            $(e.currentTarget).siblings().show()
            $(e.currentTarget).hide()
            $('.' + clickedLayout).show()
            $('.' + clickedLayout).siblings().hide()
        }
    }

    backToLayout = (e) => {
        // console.log(">>>>>> layout view");
        this.showLayoutDiv("block")
        this.showWidgetDiv("none")
        $('#next_btn').show()
        $('#update_btn').hide()
        $(e.currentTarget).hide()
        $('#custom_layout').addClass('fleet_highlight')
        $('#custom_layout').parent().siblings().children().removeClass('fleet_highlight')
        $('#custom_layout').find('input').prop("checked", true)
        $('.widget_list').appendTo('.widget_list_parent')
        $('.widget_list').find('.remove_icon').hide()
        this.state.widgetId.length = 0
    }

    okClick = () => {

        const selectedLayout = this.state.layoutId
        var widgetLayout = document.getElementsByClassName(selectedLayout)
        var widgetbody = $(widgetLayout).find('.w_content_div, .h_content_div, .vh_content_div').length
        var selectedWidget = $(widgetLayout).find('.w_content_div, .h_content_div, .vh_content_div').children()
        // console.log('>>>>>>>>>>', widgetbody);
        if (widgetbody === selectedWidget.length) {
            // console.log('in if statement')
            for (let i = 0; i < selectedWidget.length; i++) {
                const element = selectedWidget[i];
                const widgetid = $(element).prop('id')
                this.state.widgetId.push(widgetid)
                this.forceUpdate()
                console.log('state widgets', this.state.widgetId)
            }
        } else {
            this.setState({
                openAlert : true,
                errorMessage : "Please select widgets as per selected layout design"
            })
            return
            // console.log('in else statement')
        }

        if (this.state.widgetId) {
            const sendLayoutData = {fleet_config: this.state.fleet_id, layout_id: this.state.layoutId, widget_ids: String(this.state.widgetId) }
            const layoutJsonData = JSON.stringify(sendLayoutData)
            console.log('layout data is', layoutJsonData)            
            var layoutWidget = this.state.widgetId
            ApiUrlCall.createLayout(layoutJsonData).then(response => {
                // console.log('post data', response);
                this.props.history.push('/');
            }).catch(error => {
                if (layoutWidget) {
                    layoutWidget.length = 0;
                    // console.log('post data >>>>>>>>>>>', this.state.widgetId);
                }
                // console.log('post data', error.response);
            })
            // console.log('json data', layoutJsonData)
        }
    }

    // Drag and drop functionality start
    widgetDragOver = (ev) => {
        ev.preventDefault();
    }
    widgetDragEnd = (ev) => {
        // console.log('drag over endddddd', ev.target);
    }
    widgetDragStart = (ev) => {
        var dragstart = ev.dataTransfer.setData("text", ev.target.id);
    }

    widgetDropDiv = (ev) => {
        ev.preventDefault();
        var data = ev.dataTransfer.getData("text");        
        var appendDiv = document.getElementById(data)
        // console.log(ev.target, "append div is")
        ev.target.appendChild(appendDiv);
        $(ev.target).children().children().first().show()
    }

    removeWidget = (e) => {
        $(e.currentTarget.parentNode).appendTo('.widget_list_parent')
        $(e.currentTarget).hide()
        // console.log('widget data', this.state.widgetId)
    }

    // Drag and drop functionality end

    render() {
        const {t} = this.props
        return (
            <div>
                <Navbar />
                <div>
                    <div className="ongoingJourney">
                        <h1>{t('os.dashbord')}&nbsp;<span style={{ color: "#F0762B" }}>{t('os.setup')}</span></h1>


                        {/* div with empty journey start */}
                        <div className="fs_parent">
                            <Grid container>
                                <Grid item xs>
                                    <div className="fs_checkbox_parent" onClick={this.fleetSizeSelection} id="small_fleet">
                                        <div style={{ display: "table-cell" }}>
                                            <input type="radio" name="1"></input>
                                        </div>
                                        <div style={{ display: "table-cell" }}>
                                            <p>{t('os.small')}</p>
                                            <p><b>1-10 {t('os.vehicles')}</b></p>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs>
                                    <div className="fs_checkbox_parent" onClick={this.fleetSizeSelection} id="medium_fleet">
                                        <div style={{ display: "table-cell" }}>
                                            <input type="radio" name="2"></input>
                                        </div>
                                        <div style={{ display: "table-cell" }}>
                                            <p>{t('os.medium')}</p>
                                            <p><b>11-50 {t('os.vehicles')}</b></p>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs>
                                    <div className="fs_checkbox_parent" onClick={this.fleetSizeSelection} id="large_fleet">
                                        <div style={{ display: "table-cell" }}>
                                            <input type="radio" name="3"></input>
                                        </div>
                                        <div style={{ display: "table-cell" }}>
                                            <p>{t('os.large')}</p>
                                            <p><b>More than 50 {t('os.vehicles')}</b></p>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs>
                                    <div className="fs_checkbox_parent" onClick={this.fleetSizeSelection} id="map_only">
                                        <div style={{ display: "table-cell" }}>
                                            <input type="radio" name="4"></input>
                                        </div>
                                        <div style={{ display: "table-cell" }}>
                                            <p><b>{t('os.map')}</b></p>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs>
                                    <div className="fs_checkbox_parent" onClick={this.fleetSizeSelection} id="custom_layout">
                                        <div style={{ display: "table-cell" }}>
                                            <input type="radio" name="5"></input>
                                        </div>
                                        <div style={{ display: "table-cell" }}>
                                            <p><b>{t('os.custom')}</b></p>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                        {/* div with empty journey end */}

                    </div>

                    <div className="widget_setting">
                        <div>
                            <div className="graph_view_btn" style={{ display: "inline-block" }}>
                                <div className="active_view_btn" style={{ display: "inline-block" }} onClick={this.dataViewClick} id="layout_btn">
                                    <label>{t('os.layout')}</label>
                                </div>
                                <div className="inactive_view_btn" style={{ display: "inline-block" }} onClick={this.dataViewClick} id="widget_btn">
                                    <label>{t('os.widget')}</label>
                                </div>
                            </div>
                        </div>

                        {/* layout view start */}
                        <div style={{ marginTop: "50px", display: "block" }} id="layout_parent">
                            <Grid spacing={5} container>
                                <Grid item xs={4}>
                                    <div className="select_layout" onClick={this.selectLayout}>
                                        <img src="/images/01.png"></img>
                                    </div>
                                    <input type="radio" className="isHidden" name="layout" id="ld_1"></input>
                                </Grid>
                                <Grid item xs={4}>
                                    <div className="select_layout" onClick={this.selectLayout}>
                                        <img src="/images/02.png"></img>
                                    </div>
                                    <input type="radio" className="isHidden" name="layout" id="ld_2"></input>
                                </Grid>
                                <Grid item xs={4}>
                                    <div className="select_layout" onClick={this.selectLayout}>
                                        <img src="/images/03.png"></img>
                                    </div>
                                    <input type="radio" className="isHidden" name="layout" id="ld_3"></input>
                                </Grid>
                                <Grid item xs={4}>
                                    <div className="select_layout" onClick={this.selectLayout}>
                                        <img src="/images/04.png"></img>
                                    </div>
                                    <input type="radio" className="isHidden" name="layout" id="ld_4"></input>
                                </Grid>
                                <Grid item xs={4}>
                                    <div className="select_layout" onClick={this.selectLayout}>
                                        <img src="/images/05.png"></img>
                                    </div>
                                    <input type="radio" className="isHidden" name="layout" id="ld_5"></input>
                                </Grid>
                                <Grid item xs={4}>
                                    <div className="select_layout" onClick={this.selectLayout}>
                                        <img src="/images/06.png"></img>
                                    </div>
                                    <input type="radio" className="isHidden" name="layout" id="ld_6"></input>
                                </Grid>
                            </Grid>
                        </div>
                        {/* layout view end */}

                        {/* widget view start */}
                        <div style={{ marginTop: "50px", display: "none" }} id="widget_setting_parent">
                            <Grid spacing={3} container>
                                <Grid item xs={8}>
                                    {/* layout 1 start */}
                                    <div className="widget_setting_container ld_1" style={{ display: "none" }}>
                                        <Grid spacing={3} container>
                                            <Grid item xs={12}>
                                                <div className="w_ongoing"></div>
                                            </Grid>
                                        </Grid>
                                        <Grid className="add_widget" spacing={1} container>
                                            <Grid item xs spacing={1} container>
                                                <Grid item xs={6}>
                                                    <div className="w_content_div" name="widget" onDrop={this.widgetDropDiv} onDragOver={this.widgetDragOver}></div>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <div className="w_content_div" name="widget" onDrop={this.widgetDropDiv} onDragOver={this.widgetDragOver}></div>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <div className="w_content_div" name="widget" onDrop={this.widgetDropDiv} onDragOver={this.widgetDragOver}></div>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <div className="w_content_div" name="widget" onDrop={this.widgetDropDiv} onDragOver={this.widgetDragOver}></div>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <div className="w_notification"></div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    {/* layout 1 end */}
                                    {/* layout 2 start */}
                                    <div className="widget_setting_container ld_2" style={{ display: "none" }}>
                                        <Grid spacing={3} container>
                                            <Grid item xs={12}>
                                                <div className="w_ongoing"></div>
                                            </Grid>
                                        </Grid>
                                        <Grid className="add_widget" spacing={1} container>
                                            <Grid item xs spacing={1} container>
                                                <Grid item xs={6}>
                                                    <div className="h_content_div" name="widget" onDrop={this.widgetDropDiv} onDragOver={this.widgetDragOver}></div>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <div className="vh_content_div" name="widget" style={{ marginBottom: "0.5em" }} onDrop={this.widgetDropDiv} onDragOver={this.widgetDragOver}></div>
                                                    <div className="vh_content_div" name="widget" onDrop={this.widgetDropDiv} onDragOver={this.widgetDragOver}></div>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <div className="w_notification"></div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    {/* layout 2 end */}
                                    {/* layout 3 start */}
                                    <div className="widget_setting_container ld_3" style={{ display: "none" }}>
                                        <Grid spacing={3} container>
                                            <Grid item xs={12}>
                                                <div className="w_ongoing"></div>
                                            </Grid>
                                        </Grid>
                                        <Grid className="add_widget" spacing={1} container>
                                            <Grid item xs spacing={1} container>
                                                <Grid item xs={6}>
                                                    <div className="h_content_div" name="widget" onDrop={this.widgetDropDiv} onDragOver={this.widgetDragOver}></div>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <div className="h_content_div" name="widget" onDrop={this.widgetDropDiv} onDragOver={this.widgetDragOver}></div>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <div className="w_notification"></div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    {/* layout 3 end */}
                                    {/* layout 4 start */}
                                    <div className="widget_setting_container ld_4" style={{ display: "none" }}>
                                        <Grid spacing={3} container>
                                            <Grid item xs={12}>
                                                <div className="w_ongoing"></div>
                                            </Grid>
                                        </Grid>
                                        <Grid className="add_widget" spacing={1} container>
                                            <Grid item xs spacing={1} container>
                                                <Grid item xs={12}>
                                                    <div className="w_content_div" name="widget" onDrop={this.widgetDropDiv} onDragOver={this.widgetDragOver}></div>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <div className="w_content_div" name="widget" onDrop={this.widgetDropDiv} onDragOver={this.widgetDragOver}></div>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <div className="w_notification"></div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    {/* layout 4 end */}
                                    {/* layout 5 start */}
                                    <div className="widget_setting_container ld_5" style={{ display: "none" }}>
                                        <Grid spacing={3} container>
                                            <Grid item xs={12}>
                                                <div className="w_ongoing"></div>
                                            </Grid>
                                        </Grid>
                                        <Grid className="add_widget" spacing={1} container>
                                            <Grid item xs spacing={1} container>
                                                <Grid item xs={12}>
                                                    <div className="w_content_div" name="widget" onDrop={this.widgetDropDiv} onDragOver={this.widgetDragOver}></div>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <div className="w_content_div" name="widget" onDrop={this.widgetDropDiv} onDragOver={this.widgetDragOver}></div>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <div className="w_content_div" name="widget" onDrop={this.widgetDropDiv} onDragOver={this.widgetDragOver}></div>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <div className="w_notification"></div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    {/* layout 5 end */}
                                    {/* layout 6 start */}
                                    <div className="widget_setting_container ld_6" style={{ display: "none" }}>
                                        <Grid spacing={3} container>
                                            <Grid item xs={12}>
                                                <div className="w_ongoing"></div>
                                            </Grid>
                                        </Grid>
                                        <Grid className="add_widget" spacing={1} container>
                                            <Grid item xs spacing={1} container>
                                                <Grid item xs={12}>
                                                    <div className="h_content_div" name="widget" onDrop={this.widgetDropDiv} onDragOver={this.widgetDragOver}></div>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <div className="w_notification"></div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    {/* layout 6 end */}
                                </Grid>
                                <Grid item xs={4}>
                                    <div className="widget_list_parent">
                                        <div id="FleetSummary" className="widget_list" draggable="true" onDragStart={this.widgetDragStart}>
                                            <div className="remove_icon" onClick={this.removeWidget}><i className="fa fa-close" aria-hidden="true"></i></div>
                                            <img src="images/Fleet Summary.png" draggable="false"></img>
                                        </div>
                                        <div id="FuelDetails" className="s_widget_list" draggable="true" onDragStart={this.widgetDragStart}
                                        style={{display : localStorage.getItem('has_fuel_sensor') == "true" ? "" : "none"}}>
                                            <div className="s_remove_icon" onClick={this.removeWidget}><i className="fa fa-close" aria-hidden="true"></i></div>
                                            <img src="images/fuel_details.png" draggable="false"></img>
                                        </div>
                                        <div id="FuelConsumed" className="s_widget_list" draggable="true" onDragStart={this.widgetDragStart}
                                        style={{display : localStorage.getItem('has_fuel_sensor') == "true" ? "" : "none"}}>
                                            <div className="s_remove_icon" onClick={this.removeWidget}><i className="fa fa-close" aria-hidden="true"></i></div>
                                            <img src="images/fuel_consumed.png" draggable="false"></img>
                                        </div>
                                        <div id="MapView" className="widget_list" draggable="true" onDragStart={this.widgetDragStart}>
                                            <div className="remove_icon" onClick={this.removeWidget}><i className="fa fa-close" aria-hidden="true"></i></div>
                                            <img src="images/Map View.png" draggable="false"></img>
                                        </div>
                                        <div id="OverallVehicleHealth" className="widget_list" draggable="true" onDragStart={this.widgetDragStart}>
                                            <div className="remove_icon" onClick={this.removeWidget}><i className="fa fa-close" aria-hidden="true"></i></div>
                                            <img src="images/Vehicle Expenses.png" draggable="false"></img>
                                        </div>
                                        <div id="DriverEfficiency" className="widget_list" draggable="true" onDragStart={this.widgetDragStart}>
                                            <div className="remove_icon" onClick={this.removeWidget}><i className="fa fa-close" aria-hidden="true"></i></div>
                                            <img src="images/Driver Avaibility.png" draggable="false"></img>
                                        </div>
                                        <div id="VehicleUsage" className="widget_list" draggable="true" onDragStart={this.widgetDragStart}>
                                            <div className="remove_icon" onClick={this.removeWidget}><i className="fa fa-close" aria-hidden="true"></i></div>
                                            <img src="images/Daily Vehicle Usage .png" draggable="false"></img>
                                        </div>
                                        {/* <div id="VehicleFitness" className="widget_list" draggable="true" onDragStart={this.widgetDragStart}>
                                            <div className="remove_icon" onClick={this.removeWidget}><i className="fa fa-close" aria-hidden="true"></i></div>
                                            <img src="images/Driver Score.png" draggable="false"></img>
                                        </div> */}
                                        <div id="TodaysJourney" className="widget_list" draggable="true" onDragStart={this.widgetDragStart}>
                                            <div className="remove_icon" onClick={this.removeWidget}><i className="fa fa-close" aria-hidden="true"></i></div>
                                            <img src="images/Todayʼs Journeys Summary.png" draggable="false"></img>
                                        </div>
                                        <div id="VehicleDocument" className="widget_list" draggable="true" onDragStart={this.widgetDragStart}>
                                            <div className="remove_icon" onClick={this.removeWidget}><i className="fa fa-close" aria-hidden="true"></i></div>
                                            <img src="images/Vehicle Documents.png" draggable="false"></img>
                                        </div>
                                        <div id="VehicleStat" className="widget_list" draggable="true" onDragStart={this.widgetDragStart}>
                                            <div className="remove_icon" onClick={this.removeWidget}><i className="fa fa-close" aria-hidden="true"></i></div>
                                            <img src="images/vehicle stat.png" draggable="false"></img>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                        {/* widget view end */}
                        <div className="s_layout_btn">
                            <img src="images/layout_next.png" alt="icon" className="s_back_btn" style={{ display: "none" }} onClick={this.backToLayout} id="back_btn"></img>
                            <img src="images/layout_next.png" alt="icon" onClick={this.nextToWidget} id="next_btn"></img>
                            <img src="images/layout_save.png" alt="icon" width="74px" onClick={this.okClick} style={{ display: "none" }} id="update_btn"></img>
                        </div>

                        <Grid container spacing={3}>
                            <Grid item xs={8} className="widgetComponent" style={{ display: this.state.noWidgetView ? 'none' : '' }}>
                                <Grid container spacing={3}>

                                </Grid>
                            </Grid>
                            <Grid item xs={8} style={{ display: this.state.noWidgetView ? '' : 'none' }}>
                                <div className="nw_parent">
                                    <div className="nw_add_widget_parent">
                                        <div className="nw_add_widget"></div>
                                        <p>{t('os.setup.widget')}</p>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={4} style={{ display: this.state.noWidgetView ? '' : 'none' }}>
                                <div className="na_parent">
                                    <p><b>{t('ds.noalert.header')}</b></p>
                                 
                                </div>
                            </Grid>
                            <Grid item xs={4} container style={{ display: this.state.noWidgetView ? 'none' : '' }}>

                            </Grid>
                        </Grid>
                    </div>
                </div>
                <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                autoHideDuration={2000}
                onClose={(e, reason) => {
                    if (reason === 'clickaway') {
                    return;
                    }
                    this.setState({ openAlert: false })
                }
                }
                open={this.state.openAlert}
                message={this.state.errorMessage}
                />
            </div>
        )
    }
}

export default withTranslation()(DashboardSetup);
