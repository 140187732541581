import React, { Component } from 'react';
import $ from 'jquery';
import './create_new_journey.scss';
import Grid from '@material-ui/core/Grid';
import Table from 'react-bootstrap/Table';
import VehicleLists from '../vehicles/vehilcleList';
import { BrowserRouter as Router, Link, } from "react-router-dom";
import Navbar from '../../Config/Navbar';
import Calendar from 'react-calendar/dist/entry.nostyle'
import '../calendar/calendar.scss'
import CommonFunction from '../common_function';
import ApiUrlCall from '../API/apiurl'
import Map from '../map/map'
import Snackbar from '@material-ui/core/Snackbar';
import analytics from '../../Config/firebase'
import Amplify, { Analytics, selectInput } from 'aws-amplify';
import awswebapp from '../../aws-exports';
import constant from '../constant.json';
import { withTranslation } from 'react-i18next';
import Timekeeper from 'react-timekeeper';


class CreateJourneyDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      vehicleSelect: [],
      driverSelect: [],
      selectedStop: 0,
      startLocation: '',
      endLocation: '',
      startTime: '',
      startDate: new Date(),
      endDate: new Date(),
      endTime: '',
      jsonStartDateTime: '',
      jsonEndDateTime: '',
      stops: [],
      stopElement: '',
      showMapSelect: false,
      addAddressTo: false,
      markerAddressLat: 19.0760,
      markerAddressLng: 72.8777,
      address_is: '',
      latitude_is: '',
      longitude_is: '',
      MapCenter: '',
      MapContent: [],
      stopAddress: [],
      stopMapOpen: false,
      indexStop: 0,
      startLocLat: '',
      startLocLng: '',
      endLocLat: '',
      endLocLng: '',
      autoAddress: '',
      openAlert: false,
      errorMessage: '',
      stopFlag: true,
      selectedJourneyType: 0,
      TstartDate: new Date(),
      TendDate: new Date(),
      TstartTime: "12:00 am",
      sendStartTime: "00:00",
      showStartTime: false,
      TendTime: "11:59 pm",
      sendEndTime: "23:59",
      showEndTime: false,
      postJourneyContent: [],
      postCounter: 0,
      SearchVehicleList: [],
      SearchDriverList: [],
      vehicleInputSelected: '',
      driverInputSelected: '',
      selectedVehicleArray: [],
      selectedDriverArray: [],
      // assignedObj : []
      recurringType : 1,
      isRecurring : false,
      recurringDays : "",
      includeSunday : false,
      stopAlert : false,
      journeyStopage : '',
      startName : '',
      endName : '',
      PudoUserList : [],
      chckebox_value: [],
      userRole : 0,
    }
  }

  componentDidMount() {
    analytics.logEvent('screen_view', {
      screen_name: "CreateJourney"
    })
    analytics.logEvent('page_view', {
      page_title: "CreateJourney",
    })
    Amplify.configure(awswebapp);
    Analytics.record({
      name: constant.awsName,
      // Attribute values must be strings
      attributes: { screen_name: 'WEB_CREATE_JOURNEY' }
    });
    // console.log('>>>>>>>>>> props', this.props.location)
    var navBarHeight = $('.navBar').outerHeight();
    $('.create_journey_parent').css({
      paddingTop: navBarHeight + 60,
    })
     this.getPudoUserList()
    
     
  }

  handleChange(e) {
    // current array of options
    const options = this.state.chckebox_value
    let index

    // check if the check box is checked or unchecked
    if (e.target.checked) {
      // console.log(e.target.checked, "+++++++++++++++++++")
      // add the numerical value of the checkbox to options array
      options.push(+e.target.value)
      //$('#region_select').slideUp(200)
    } else {
      // or remove the value from the unchecked checkbox from the array
      index = options.indexOf(+e.target.value)

      options.splice(index, 1)
    }

    // sort the array
    options.sort()

    // update the state with the new array of options
    this.setState({ options: options })
  }

  getPudoUserList = () =>{
    $('#processing').show()
    var data = {role_id : 4}
    ApiUrlCall.ShowPudoUserList(JSON.stringify(data)).then(response => {            
        //this.emptyTable()
        if(response.data.response_code === 200 && response.data.is_success){
          $('#processing').hide()          
          this.setState({
            PudoUserList: response.data.data.vender_list
          },() => {
            //this.createTableData()          
            //console.log(">>>>>>>>>>>>>. vender_list ",this.state.PudoUserList)
          })
        }else{
          $('#processing').hide()
        }
    }).catch(error => {
        $('#processing').hide()
        console.log('error while fetching getPudoUserList list', error)
    })
}


  UNSAFE_componentWillMount() {
    this.getMyLocation()
  }


  // this method is used to create and send data to create journey API
  addVehicleDriver = () => {

    let validationField = CommonFunction.handleValidation(this.state.startLocation, this.state.endLocation, this.state.startDate, this.state.endDate,
      this.state.startTime, this.state.endTime)
    // console.log(CommonFunction.ServerDateTime(this.state.startDate)) 
    if(this.state.isRecurring && !this.state.recurringDays){
      this.setState({
        openAlert : true,
        errorMessage : "Kindly Enter Number Of Days"
      })
      return
    }
    if (validationField && this.state.stopFlag) {
      if (this.state.jsonStartDateTime >= this.state.jsonEndDateTime) {
        this.setState({
          openAlert: true,
          errorMessage: 'Selected datetime is invalid'
        })
      } else {
        var sendJsonData = {
          start_name : $("#start_name").val() , end_name : $("#end_name").val(),
          start_time: CommonFunction.ServerDateTime(this.state.jsonStartDateTime), end_time: CommonFunction.ServerDateTime(this.state.jsonEndDateTime),
          planned_start_loc: this.state.startLocation, planned_end_loc: this.state.endLocation, planned_start_loc_lat: this.state.startLocLat,
          planned_start_loc_lon: this.state.startLocLng, planned_end_loc_lat: this.state.endLocLat, planned_end_loc_lon: this.state.endLocLng, created_at: CommonFunction.ServerDateTime(this.state.date),
          created_by: localStorage.getItem('user_id'), stops: this.state.stops, 
          recurring_type : this.state.recurringType, 
          recurring_days : this.state.recurringDays,
          is_sunday_journey : this.state.includeSunday, 
          is_stop_alert : this.state.stopAlert, 
          is_recurring : this.state.isRecurring, 
          stopage_duration : CommonFunction.convertMinToSec(this.state.journeyStopage),
          is_auto_start: this.state.isAutoStart,
        }
        var jsonSendJsonData = JSON.stringify(sendJsonData)
        //console.log('data to be sent to server', jsonSendJsonData)
        //return
        ApiUrlCall.createNewJourney(jsonSendJsonData).then(response => {
          if (response.status === 201) {
            this.props.history.push({
              pathname: 'create_new_journey',
              state: { journey_id: response.data.id,
                        moving_state : 4
              }

              
            })
          }

        }).catch(error => {
          this.setState({
            openAlert: true,
            errorMessage: "Stop details are invalid"
          })
          console.log("error while creating journey", error.response.data)

        })
      }
    } else {
      this.setState({
        openAlert: true,
        errorMessage: "Invalid data of stop details or journey details"
      })
    }
  }

  // enteredStops = (e) => {
  //   var stopObj = { location: e.currentTarget.value, lat: 2.0, lon: 1.0, duration: 5 }
  //   console.log('stopObj', stopObj)
  //   this.state.stops.push(stopObj)
  //   console.log('array of stops', this.state.stops)
  // }


  // this method is used to remove the stops from stops and stopElemet from state on click of delete icon.
  removeStops = (e) => {
    $('.stops_parent').find('.fa-trash-alt').hide()
    $('.stops_parent').last().prev().find('.fa-trash-alt ').show()
    var removeStopArray = this.state.stops
    var removeStopElementArray = this.state.stopElement
    var decreaseCounter = this.state.selectedStop - 1
    var deleteLocation = $(e.currentTarget).attr('name')
    var removeElement = $(e.currentTarget).parent().parent().attr('name')
    // var deleteStopIndex = removeStopArray.findIndex(x => x.location === deleteLocation)
    // console.log('remove element', removeElement)
    var deleteStopElementIndex = removeStopElementArray.findIndex(y => y.props.id === parseInt(removeElement))
    removeStopElementArray.map((items, index, removeStopElementArray) => {
      // console.log('itmes', items.props)
      if (items.props.name === parseInt(removeElement)) {
        removeStopElementArray.splice(deleteStopElementIndex, 1)
        this.setState({
          selectedStop: decreaseCounter
        }, () => {
          // console.log('previous counter value is', this.state.selectedStop)
          // console.log('array of stop data', this.state.stopElement)
        })
        this.forceUpdate()
      }
    })
    removeStopArray.splice(deleteLocation, 1)
    this.setState({
      selectedStop: decreaseCounter
    })
    this.forceUpdate()
    // console.log('stops data after delete', this.state.stops)
  }

  // this method is used to add stops on click of add more
  stopNameSet = (i,e) => {
    const stop_name_id = 'stop_'+i
    const stop_name = $("#"+stop_name_id).val()  
    const stopuser_id = 'stopuser_'+i

    const stop_user = $("#"+stopuser_id).val()              
    this.state.stops[i].name = stop_name
    this.state.stops[i].person_id = stop_user
    this.forceUpdate()
    

  }
  returNumberOfStop = (e) => {
    const stops = []
    const { t } = this.props
    // console.log('stop value in array after add new stop', this.state.stops)
    var stopsValue = this.state.selectedStop + 1
    var pudouser_list = this.state.PudoUserList.map(pudouser_list => {
      return (
        <option key={pudouser_list.user_id} value={pudouser_list.user_id}>{pudouser_list.first_name}</option> 
      )
    })
    for (let i = 0; i < stopsValue; i++) {
      stops.push(
        <Grid container spacing={4} item xs={11} key={i} name={i} className="stops_parent">
          <Grid item xs={5}>
            <p className="cj_w_header">{t('ct.stop')} {i + 1}</p>
            
            <div className="input_div pointer" onClick={() => this.openStopMap(i)}>
              <input type="text" placeholder={t('ct.start.location')} id={i} className="journey_input pointer" disabled></input>
              <img src="images/cj_location.png"></img>
            </div>
            <br/>
            <div className="input_div pointer" >
              <input type="text" placeholder="Stop Name" id={'stop_'+i} onChange={() => this.stopNameSet(i)} className="journey_input pointer"></input>
              <img src="images/cj_location.png"></img>
            </div>
          </Grid>
          <Grid item xs={5}>
          <div>
            <p className="cj_w_header">{t('ct.duration')}</p>
            <input type="text" placeholder={t('ct.minutes')} id={'dur_' + i} defaultValue={constant.stop_duration} disabled className="journey_input" onBlur={(e) => {
              if (this.state.stops) {
                if (e.currentTarget.value >= constant.stop_duration) {
                  const stop_name_id = 'stop_'+i
                  const stop_name = $("#"+stop_name_id).val()
                  this.state.stops[i].duration = e.currentTarget.value
                  this.state.stops[i].name = stop_name
                  //console.log( e. , "STOP VAL")
                  this.setState({
                    stopFlag: true,
                  })
                  // console.log('after duration change', this.state.stops)
                } else {
                  this.setState({
                    openAlert: true,
                    errorMessage: `Stop Duration should be greater than equal to ${constant.stop_duration} minutes`,
                    stopFlag: false,
                  })
                }
              }
            }}></input>
            </div>
            <br/>
            <Grid item xs={12}>              
                <select id={'stopuser_'+i} className="pudo-filter journey_input" style={{ width: "100%" }}
                    onChange={(e) => {


                        for (const option of document.getElementById('stopuser_'+i)) {
                          //console.log(option);
                          if (option.value === e.currentTarget.value)
                          {
                            option.selected = true
                          }
                          else{
                            option.selected = false                            
                          }

                        }
                        this.stopNameSet(i)

  
                    }}
                    >
                    <option value="0">{t('ct.selectpudo')}</option> 
                    {pudouser_list}
                </select>
            </Grid>
          </Grid>          
          <Grid item xs={1}>
            <p>&nbsp;</p>
            <i class="fas fa-trash-alt" aria-hidden="true" name={i} style={{ color: "#F0762B", marginTop: "10px", fontSize: "20px" }} onClick={this.removeStops}></i>
          </Grid>
        </Grid>
      )
    }
    //console.log('stops array is', stops)
    this.setState({
      stopElement: stops,
      selectedStop: stopsValue
    }, () => {
      $('.stops_parent').find('.fa-trash-alt').hide()
      $('.stops_parent').last().find('.fa-trash-alt').show()
      // console.log("stop array after state set is", this.state.stopElement)
    })
  }


  // this method is used to create start date into ISO and send it to server
  StartdateClicked = date => {
    var isoStartDate = date
    // console.log('props value', date.toISOString())
    this.setState({ startDate: isoStartDate })
    $('#start_date_cal').slideUp(200)
  }

  // this method is used to create end date into ISO and send it to server
  postEnddateClicked = date => {
    // console.log('props value', date.toISOString())
    this.setState({ TendDate: date })
    $('#post_end_date_cal').slideUp(200)
  }

  postStartdateClicked = date => {
    // console.log('props value', date.toISOString())
    this.setState({ TstartDate: date })
    $('#post_start_date_cal').slideUp(200)
  }

  // this method is used to create end date into ISO and send it to server
  EnddateClicked = date => {
    var isoStartDate = date
    // console.log('props value', date.toISOString())
    this.setState({ endDate: isoStartDate })
    $('#end_date_cal').slideUp(200)
  }

  showStartDate = (e) => {
    $(e.currentTarget).next().slideToggle(200, "swing")
  }
  showEndDate = (e) => {
    $(e.currentTarget).next().slideToggle(200, "swing")
  }

  // this method is used to formate date as per UI to show.
  dateFormate = (rdate) => {
    var date = new Date(rdate);
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    if (day < 10) {
      day = '0' + day;
    }
    if (month < 10) {
      month = '0' + month;
    }
    var convertDate = day + '/' + month + '/' + year
    return convertDate
  }

  showTimePicker = (e) => {
    $(e.currentTarget).next().slideToggle(200, "swing")
  }

  // this method is used to create start time and end time as required to server in ISO formate
  startTimeSelected = (e) => {
    var hide_div = $(e.currentTarget).parents()[2]
    var hours = $('#startHours').val()
    var minutes = $('#startMins').val()
    var am_pm = $('#startAmPm').val()
    if (hours < 10) {
      hours = '0' + hours;
    }
    var initialTime = hours + ':' + minutes + ' ' + am_pm
    this.setState({
      startTime: initialTime
    })
    $(hide_div).hide()
    // console.log("time is", hours + ':' + minutes + am_pm)
    var createStartDate = this.createDate(this.state.startDate, initialTime)
    // console.log(this.state.date,'>>>>>>>>>>>>>>>', createStartDate)
    if (this.state.date > createStartDate) {
      this.setState({
        stopFlag: false,
        openAlert: true,
        errorMessage: 'Selected time should be greater than current time'
      })
      return
    } else {
      this.setState({
        stopFlag: true,
      })
    }
    this.setState({
      jsonStartDateTime: createStartDate
    })
  }
  endTimeSelected = (e) => {
    var hide_div = $(e.currentTarget).parents()[2]
    var hours = $('#endHours').val()
    var minutes = $('#endMins').val()
    var am_pm = $('#endAmPm').val()
    if (hours < 10) {
      hours = '0' + hours;
    }
    var initialTime = hours + ':' + minutes + ' ' + am_pm
    this.setState({
      endTime: initialTime
    })
    // console.log('>>>>>>>>>>>>>>>')
    $(hide_div).hide()
    // console.log("time is", hours + ':' + minutes + am_pm)
    var createEndDate = this.createDate(this.state.endDate, initialTime)
    if (this.state.date > createEndDate) {
      this.setState({
        stopFlag: false,
        openAlert: true,
        errorMessage: 'Selected time should be greater than current time'
      })
      return
    } else {
      this.setState({
        stopFlag: true,
      })
    }
    this.setState({
      jsonEndDateTime: createEndDate
    })
    // console.log('>>>>>>>>>>>>>>>', createEndDate)
  }

  // this method is used to combine date and time together and create a date in ISO formate and send to server
  createDate = (date1, time1) => {
    var date = date1
    // console.log('date is',date)
    var onlyDate = CommonFunction.getParsedDate(date)
    var time = time1
    // console.log(date,'//////////',onlyDate,'date', time)  
    if (time) {
      var hours = Number(time.match(/^(\d+)/)[1]);
      var minutes = Number(time.match(/:(\d+)/)[1]);
      var AMPM = time.match(/\s(.*)$/)[1];
      if (AMPM == "pm" && hours < 12) hours = hours + 12;
      if (AMPM == "am" && hours == 12) hours = hours - 12;
      var sHours = hours.toString();
      var sMinutes = minutes.toString();
      if (hours < 10) sHours = "0" + sHours;
      if (minutes < 10) sMinutes = "0" + sMinutes;
    }
    var convertedTime = sHours + ":" + sMinutes + ":00"
    var sendDate = new Date(onlyDate + ' ' + convertedTime)
    // console.log('only date', sendDate)
    return sendDate
    // console.log("date time", sendDate.toISOString())
  }

  // this is used to get my current location
  getMyLocation = () => {
    // console.log('get my location')
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.MyLocation);
    }
  }
  MyLocation = (position) => {
    // console.log('my position', position)
    this.setState({
      markerAddressLat: position.coords.latitude,
      markerAddressLng: position.coords.longitude,
    })
  }


  // this method is used to render MAP
  renderMap = () => {
    let mapArray = []
    // console.log('1111111111111111111')
    // if(this.state.markerAddressLat && this.state.markerAddressLng){
    // console.log('222222222222222222')
    mapArray.push(
      <Map
        markers={
          [
            {
              position: [this.state.markerAddressLat, this.state.markerAddressLng],
              icon: '',
              draggable: true,
              // title: this.state.address_is,
              onClick: () => { },
              onDragend: (e) => {
                this.setState({
                  markerAddressLat: e.target._latlng.lat,
                  markerAddressLng: e.target._latlng.lng
                }, () => {
                  this.getLatLng(this.state.markerAddressLat, this.state.markerAddressLng)
                  // console.log(this.state.markerAddressLat, 'lat---------', this.state.markerAddressLng, 'lng--------')
                })
              },
              setViewFlag: true,
              rotateMarker: false
            }
          ]
        }
        cluster={false}
        // onClick={this.getLatLng}
        height={"650px"}
      />
    )
    // }
    this.setState({
      MapContent: mapArray
    })
  }

  // open address selection i.e MAP on stops click
  openStopMap = (position, e) => {
    this.setState({
      showMapSelect: true,
      address_is: '',
      openStopMap: false,
      indexStop: position
    }, () => {
      this.renderMap()
      window.dispatchEvent(new Event('resize'));
      this.hideScroll()
    })
  }

  hideScroll = () => {
    $('html, body').css({
      overflow: 'hidden',
      height: '100%'
    });
    $('.create_journey_parent').scrollTop()
  }

  showScroll = () => {
    $('html, body').css({
      overflow: 'scroll',
      height: '100%'
    });
  }


  // open address selection i.e MAP on start and end locatino
  openMap = (e) => {
    var addressAppend = (() => {
      if ($(e.currentTarget).attr('name') === "end_location_is") {
        return true
      } else {
        return false
      }
    })()
    // console.log(addressAppend,">>>>>>><<<<<<<<<>>>>>>>>>><<<<<<<<<", $(e.currentTarget).attr('name'))
    this.setState({
      showMapSelect: true,
      addAddressTo: addressAppend,
      address_is: '',
      openStopMap: true
    }, () => {
      this.renderMap()
      window.dispatchEvent(new Event('resize'));
      this.hideScroll()
    })
    // console.log('open map click event')
  }

  // this method is used to get address form mongo DB
  getLatLng = (lat, lng) => {
    let addressLatLng = { lat: lat, lng: lng }
    ApiUrlCall.getAddressMMI(JSON.stringify(addressLatLng)).then(response => {
      // console.log('address is', response)
      if (response.data.response_code === 200 & response.data.is_success === true) {
        this.setState({
          address_is: response.data.data.address,
          latitude_is: response.data.data.lat,
          longitude_is: response.data.data.lng
        }, () => {
          $('#search_address_val').val(this.state.address_is)
          if (!this.state.address_is) {
            this.setState({
              openAlert: true,
              errorMessage: 'Address Not found',
            })
          }
        })
      }
    }).catch(error => {
      console.log('error in request', error.res)
    })
  }

  // this method is used to close address modal
  closeMapModal = () => {
    // console.log('close modal colled')
    this.setState({
      showMapSelect: false,
      address_is: ''
    }, () => {
      $('#search_address_val').val("")
      // console.log('scroll active')
      this.showScroll()
    })
  }

  // this method used to save and show address after the selection.
  saveAddress = (e) => {
    var stopObj = { location: this.state.address_is, lat: this.state.markerAddressLat, lon: this.state.markerAddressLng, duration: constant.stop_duration }
    var duratinoInput = '#dur_' + this.state.indexStop
    // console.log('>>>>>>>>>>>>>', stopObj)
    this.showScroll()
    $('#search_address_val').val("")
    if (this.state.openStopMap) {
      // console.log('....................', this.state.addAddressTo)
      if (this.state.addAddressTo) {
        this.setState({
          endLocation: this.state.address_is,
          endLocLat: this.state.markerAddressLat,
          endLocLng: this.state.markerAddressLng,
          showMapSelect: false
        }, () => {
          // console.log(this.state.endLocLat,'end location',this.state.endLocLng)
        })
      } else {
        this.setState({
          startLocation: this.state.address_is,
          startLocLat: this.state.markerAddressLat,
          startLocLng: this.state.markerAddressLng,
          showMapSelect: false
        }, () => {
          // console.log(this.state.startLocLat,'start location',this.state.startLocLng)
        })
      }
    } else {
      // console.log('array push is', this.state.indexStop)
      // this.state.stopAddress[this.state.indexStop] = this.state.address_is
      if (typeof this.state.stops[this.state.indexStop] === "undefined") {
        // console.log('---------------- undefined')
        if (this.state.address_is) {
          this.setState({
            showMapSelect: false,
            stopMapOpen: true,
            stopAddress: [...this.state.stopAddress, this.state.address_is],
            stops: [...this.state.stops, stopObj]

          }, () => {
            // console.log('duratino text box is', $(duratinoInput))
            document.getElementById(this.state.indexStop).value = this.state.stopAddress[this.state.indexStop]
            $(duratinoInput).prop('disabled', false)
            // console.log('stops data is', this.state.stops)
            // console.log(this.state.endLocLat,'end location 333333333',this.state.endLocLng)
            // console.log(this.state.startLocLat,'start location 4444444',this.state.startLocLng)
          })
        }
      } else {
        if (this.state.address_is) {
          this.state.stopAddress[this.state.indexStop] = this.state.address_is
          this.state.stops[this.state.indexStop].location = this.state.address_is
          this.forceUpdate()
          this.setState({
            showMapSelect: false,
            stopMapOpen: true,
          }, () => {
            document.getElementById(this.state.indexStop).value = this.state.stopAddress[this.state.indexStop]
            $(duratinoInput).prop('disabled', false)
          })
        }
        // console.log('----------- defined')
      }
      // this.forceUpdate()
      // console.log('stop address in array is', this.state.stopAddress)
      // console.log(this.state.endLocLat,'end location 1111111111111',this.state.endLocLng)
      // console.log(this.state.startLocLat,'start location 22222222222',this.state.startLocLng)
    }
  }

  validateLatLng = (lat, lng) => {    
    let pattern = new RegExp('^-?([1-8]?[1-9]|[1-9]0)\\.{1}\\d{1,6}');
    
    return pattern.test(lat) && pattern.test(lng);
  }
  checkstringlatlng = (value) => {
    let flag = false
    let geodata = value.split(",")

    if (geodata.length == 2)
    {  flag = this.validateLatLng(geodata[0].trim(),geodata[1].trim()) }
    return flag
  }

  //get address from MMI api call

  mmiGetAddressApi = (e) => {
    var address = e.currentTarget.value
    if (e.currentTarget.value.length > 4) {
      if (this.checkstringlatlng(address))
      {
        let geodata = address.split(",")

        if (geodata.length == 2)
        {  
          let addressLatLng = { lat: geodata[0].trim(), lng: geodata[1].trim() }
          ApiUrlCall.getAddressMMI(JSON.stringify(addressLatLng)).then(response => {

            if (response.data.response_code === 200 & response.data.is_success === true) {      
              if (response.data.data.address != "")
              {
                this.setAddressUsinglatlng(response.data.data.address,geodata[0].trim(),geodata[1].trim())
              }
              else{
                if (!this.state.address_is) {
                      this.setState({
                        openAlert: true,
                        errorMessage: 'Address Not found',
                      })
                    }
              }
            }
          }).catch(error => {
            console.log('error in request', error.res)
          })
        }
      }
      else{
      ApiUrlCall.getAddressIQ(address).then(response => {
        if (response.status === 200) {
          this.setState({
            autoAddress: response.data.data.address_list
          }, () => {
            // console.log('address in state is', this.state.autoAddress)
          })
        }
        $('#address_list').slideDown(200, "swing")
        // console.log('response of address is', response)
      }).catch(error => {
        console.log('error while fetching address from MMI', error)
      })
    }
    } else {
      $('#address_list').fadeOut(200)
    }
  
  }

  setAddressUsinglatlng = (address,lat,lng) => {
    this.setState({
      address_is: address,
      markerAddressLat: lat,
      markerAddressLng: lng,
    }, () => {
      $('#search_address_val').val(this.state.address_is)
      this.renderMap()
    })
    $('#address_list').fadeOut(200)
  }

  getAddressData = (e) => {
    const { autoAddress } = this.state
    var indexVal = $(e.currentTarget).attr('name')
    // console.log('index value is', indexVal)
    this.setState({
      address_is: autoAddress[indexVal].display_name,
      markerAddressLat: parseFloat(autoAddress[indexVal].lat),
      markerAddressLng: parseFloat(autoAddress[indexVal].lon),
    }, () => {
      $('#search_address_val').val(this.state.address_is)
      this.renderMap()
      // console.log('address is', this.state.address_is)
      // console.log('address is', this.state.markerAddressLat)
      // console.log('address is', this.state.markerAddressLng)
    })
    $('#address_list').fadeOut(200)
  }

  selectPreJourney = (e) => {
    if (e.currentTarget.checked) {
      this.setState({
        selectedJourneyType: 0,
      })
    }
  }

  selectPostJourney = (e) => {
    if (e.currentTarget.checked) {
      this.setState({
        selectedJourneyType: 1,
      })
    }
  }

  selectStartTime = (newTime) => {
    this.setState({
      TstartTime: newTime.formatted12,
      sendStartTime: newTime.formatted24
    })
  }

  showStartTimeContent = () => {
    if (this.state.showStartTime) {
      this.setState({
        showStartTime: false
      })
    } else {
      this.setState({
        showStartTime: true
      })
    }
  }

  selectEndTime = (newTime) => {
    this.setState({
      TendTime: newTime.formatted12,
      sendEndTime: newTime.formatted24
    })
  }

  showEndTimeContent = () => {
    if (this.state.endStartTime) {
      this.setState({
        showEndTime: false
      })
    } else {
      this.setState({
        showEndTime: true
      })
    }
  }

  createContent = () => {
    const { postCounter } = this.state
    var array = []
    // var arrobj = []   
    for (let i = 0; i < postCounter; i++) {
      array.push(
        <Grid container className="div_driver_vehicle" id={"dv_" + i} spacing={2} item xs={6}>
          <Grid item xs style={{ position: "relative" }}>
            <p>Vehicle</p>
            <input type="text" id={"vehicle_search_" + i} placeholder="Search Vehicle" className="journey_input" onBlur={(e) => {
              $(e.currentTarget).next().slideUp()
            }} onKeyUp={this.searchVehicle} autoComplete="off" onKeyDown={this.deleteVehicle}></input>
            <span className="serial_number" style={{ display: "none" }}></span>
          </Grid>
          <Grid item xs style={{ position: "relative" }}>
            <p>Driver</p>
            <input type="text" id={"driver_search_" + i} placeholder="Search Driver" className="journey_input" onBlur={(e) => {
              $(e.currentTarget).next().slideUp()
            }} onKeyUp={this.searchDriver} autoComplete="off" onKeyDown={this.deleteDriver}></input>
            <span className="driver_id" style={{ display: "none" }}></span>
          </Grid>
          <Grid item xs={1} style={{ textAlign: "right" }}>
            <p>&nbsp;</p>
            <i class="fas fa-trash-alt delete_driver_vehicle" aria-hidden="true" name={i} style={{ color: "#F0762B", marginTop: "10px", fontSize: "20px" }}
              onClick={this.deleteVehicleDriver}></i>
          </Grid>
        </Grid>
      )
    }

    // for (let j = 0; j < postCounter; j++) {
    //   var data = {serial_number : "", driver_id : ""}
    //   arrobj.push(data)
    // }
    this.setState({
      postJourneyContent: array,
      // assignedObj : arrobj
    }, () => {
      $('.div_driver_vehicle').find('.fa-trash-alt').hide()
      $('.div_driver_vehicle').last().find('.fa-trash-alt').show()
      // console.log('assinged objec is', this.state.assignedObj)
    })
  }

  deleteVehicle = (e) => {
    var key = e.key
    if (key === "Backspace" || key === "Delete") {
      $(e.currentTarget).next().text("")
    }
  }

  deleteDriver = (e) => {
    var key = e.key
    if (key === "Backspace" || key === "Delete") {
      $(e.currentTarget).next().text("")
    }
  }

  searchVehicle = (e) => {
    var height = $(e.currentTarget).outerHeight()
    var width = $(e.currentTarget).width()
    var top = $(e.currentTarget).offset().top + height
    var left = $(e.currentTarget).offset().left
    var id = $(e.currentTarget).prop('id')
    this.setState({
      vehicleInputSelected: id
    })
    if (e.currentTarget.value.length > 3) {
      $('#vehicle_list').css({
        top: top,
        left: left,
        width: width
      })
      var data = { next_count: 0, search: e.currentTarget.value, search_column: "vehicle_name" }
      ApiUrlCall.getVehicleList(JSON.stringify(data)).then(response => {
        if (response.data.response_code === 200 && response.data.is_success) {
          $(e.currentTarget).next().slideDown()
          this.setState({
            SearchVehicleList: response.data.data.vehicle_summary_list
          }, () => {
            $('#vehicle_list').slideDown()
          })
        }
      }).catch(error => {
        console.log('error while fetching vehicle list', error)
      })
    } else {
      this.setState({
        SearchVehicleList: []
      }, () => {
        $('#vehicle_list').slideUp()
      })
    }
  }

  searchDriver = (e) => {
    var height = $(e.currentTarget).outerHeight()
    var width = $(e.currentTarget).width()
    var top = $(e.currentTarget).offset().top + height
    var left = $(e.currentTarget).offset().left
    var id = $(e.currentTarget).prop('id')
    this.setState({
      driverInputSelected: id
    })
    if (e.currentTarget.value.length > 3) {
      $('#driver_list').css({
        top: top,
        left: left,
        width: width
      })
      var data = { next_count: 0, search: e.currentTarget.value }
      ApiUrlCall.getDriverDetails(JSON.stringify(data)).then(response => {
        // console.log('driver list', response)
        if (response.data.response_code === 200 && response.data.is_success) {
          $(e.currentTarget).next().slideDown()
          this.setState({
            SearchDriverList: response.data.data.driver_list
          }, () => {
            $('#driver_list').slideDown()
          })
        }
      }).catch(error => {
        console.log('error while fetching vehicle list', error)
      })
    } else {
      this.setState({
        SearchDriverList: []
      }, () => {
        $('#driver_list').slideUp()
      })
    }
  }

  assignVehicleDriver = () => {
    this.setState({
      postCounter: this.state.postCounter + 1
    }, () => {
      this.createContent()
    })
  }

  renameIcon = () => {
    var deleteIcon = $('#driver_vehicle_parent').children()
    for (let i = 0; i < deleteIcon.length; i++) {
      const element = deleteIcon[i];
      var icon = $(element).find('.delete_driver_vehicle')
      $(icon).attr('name', i)
    }
    this.setState({
      postCounter: this.state.postCounter - 1
    })
  }

  deleteVehicleDriver = (e) => {
    const { postJourneyContent } = this.state
    var selectedDelete = $(e.currentTarget).attr('name')
    postJourneyContent.splice(selectedDelete, 1)
    this.forceUpdate()
    setTimeout(() => {
      this.renameIcon()
      $('.div_driver_vehicle').find('.fa-trash-alt').hide()
      $('.div_driver_vehicle').last().find('.fa-trash-alt').show()
    }, 500);
  }

  vehicleListItem = (e) => {
    const { vehicleInputSelected } = this.state
    var serial_number = $(e.currentTarget).prop('id')
    var selectedInput = document.getElementById(vehicleInputSelected)
    document.getElementById(vehicleInputSelected).value = $(e.currentTarget).text()
    $(selectedInput).next().text(serial_number)
    $('#vehicle_list').slideUp()
    this.setState({
      SearchVehicleList: [],
      vehicleInputSelected: ''
    })
    // selectedVehicleArray.push(serial_number)
    // this.forceUpdate()
  }

  driverListItem = (e) => {
    const { driverInputSelected } = this.state
    var driver_id = $(e.currentTarget).prop('id')
    var selectedInput = document.getElementById(driverInputSelected)
    document.getElementById(driverInputSelected).value = $(e.currentTarget).text()
    $(selectedInput).next().text(driver_id)
    $('#driver_list').slideUp()
    this.setState({
      SearchDriverList: [],
      driverInputSelected: ''
    })
    // selectedDriverArray.push(driver_id)
    // this.forceUpdate()
  }

  createVehicleDriver = () => {
    var driverVehicleDiv = $('.div_driver_vehicle')
    var array = []
    if (driverVehicleDiv.length > 0) {
      for (let i = 0; i < driverVehicleDiv.length; i++) {
        const element = driverVehicleDiv[i];
        var serial_number = $(element).find('.serial_number').text()
        var driver_id = $(element).find('.driver_id').text()
        var data = { serial_number: serial_number, driver_id: driver_id }
        array.push(data)
      }
      return array
    } else {
      this.setState({
        openAlert: true,
        errorMessage: "Kindly Select Vehicle and Driver"
      })
      return
    }
  }

  validateVD = (obj) => {
    if (obj !== undefined) {
      var flag = true
      for (let i = 0; i < obj.length; i++) {
        const element = obj[i];
        if (element.serial_number && element.driver_id) {
          flag = true
        } else {
          flag = false
        }
      }
      return flag
    }
  }

  createPostJourney = () => {
    const { TstartDate, TendDate, sendEndTime, sendStartTime, selectedDriverArray, selectedVehicleArray } = this.state
    var DriverVehicleList = this.createVehicleDriver()
    var validateVehicleDriver = this.validateVD(DriverVehicleList)
    if (!validateVehicleDriver) {
      this.setState({
        openAlert: true,
        errorMessage: "Select Driver and Vehicle"
      })
      return
    }
    var data = {
      start_date: CommonFunction.createServerDateTime(TstartDate, sendStartTime), end_date: CommonFunction.createServerDateTime(TendDate, sendEndTime),
      assigned_list: DriverVehicleList
    }
    var dateValidate = CommonFunction.dateValidation(data.start_date, data.end_date)
    if (dateValidate === false) {
      this.setState({
        openAlert: true,
        errorMessage: "Selected date is invalid"
      })
      return
    }
    ApiUrlCall.createPostJourney(JSON.stringify(data)).then(response => {
      if (response.data.response_code == 201 && response.data.is_success) {
        this.props.history.push({
          pathname: '/journey'
        })
      } else {
        this.setState({
          openAlert: true,
          errorMessage: response.data.response_message
        })
      }
    }).catch(error => {
      console.log('error while creating post journey', error)
    })
  }

  render() {
    var { autoAddress, postJourneyContent, SearchVehicleList, SearchDriverList } = this.state
    const { t } = this.props
    var minDate = new Date(); minDate.setDate(minDate.getDate());
    var arrayOfStops = this.state.stopElement
    if (this.state.stopElement) {
      var stopContent = arrayOfStops.map(stopContent => {
        return stopContent
      })
    }

    if (autoAddress) {
      var address_list = autoAddress.map((list, index) => {
        return <p className="search_address" name={index}
          onClick={this.getAddressData}>{list.display_name}</p>
      })
    }

    if (postJourneyContent.length > 0) {
      var driver_vehicle = postJourneyContent.map(data => {
        return data
      })
    }

    if (SearchVehicleList.length > 0) {
      var vehicle_list = SearchVehicleList.map(list => {
        return <p id={list.serial_number} onClick={this.vehicleListItem}>{list.vehicle_name}</p>
      })
    }
    if (SearchDriverList.length > 0) {
      var driver_list = SearchDriverList.map(list => {
        return <p id={list.id} onClick={this.driverListItem}>{list.driver_name}</p>
      })
    }

    // console.log('render has been called>>>>>>>>>>>>>>>>>>>>>>>') 
    return (
      <div>
        <Navbar />
        <div className="create_journey_parent">
          {/* add vehicle and driver to journey */}
          <div style={{ paddingLeft: "100px", paddingRight: "100px", marginBottom: "30px" }}>
            <h3 className="page_header">
              <Link to="journey"><img src="images/arrow.png" alt="icon"></img></Link>
              <p style={{ display: "inline" }}>&nbsp;&nbsp;{t('dvjs.create')}</p>
            </h3>
          </div>

          {/* end */}

          {/* enter journey details */}
          <div id="enter_journey_details" className="journey_detail_div">
            <p className="p_header" style={{ marginBottom: "20px" }}>{t('dvjs.detail')}</p>
            <Grid container style={{ marginBottom: "20px" }}>
              <label class="radio-inline cj_w_header" style={{ fontSize: "18px" }}>
                <input type="radio" id="preselect" name="optradio" defaultChecked="true" onClick={this.selectPreJourney}></input>Predefined Journey
                </label>
              {/* <label class="radio-inline cj_w_header" style={{ fontSize: "18px" }}>
                <input type="radio" id="postselect" name="optradio" onClick={this.selectPostJourney}></input>Postdefined Journey
                </label> */}
            </Grid>
            <div style={{ display: this.state.selectedJourneyType == 0 ? "" : "none" }}>
              <Grid container>
                <Grid item xs={4}>
                  <p className="cj_w_header">Start Name</p>
                  <div className="input_div pointer" name="start_name" >
                    <input type="text" placeholder="Enter Start Name" id="start_name" className="journey_input pointer" ></input>
                  </div>
                  <p className="BSC_semi_bold18"><b>{t('ct.enter.location')}</b></p>
                  <p className="cj_w_header">{t('ct.start.location')}</p>
                  <div className="input_div pointer" name="start_location_is" onClick={this.openMap}>
                    <input type="text" placeholder={t('ct.start.location')} id="start_location" className="journey_input pointer" value={this.state.startLocation} disabled></input>
                    <img src="images/cj_location.png" alt="icon" width="15px"></img>
                  </div>
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={4}>
                  <p className="">&nbsp;</p>
                  <p className="cj_w_header">End Name</p>
                  <div className="input_div pointer" name="end_name" >
                    <input type="text" placeholder="Enter End Name" id="end_name" className="journey_input pointer" ></input>
                  </div>
                  <p className="cj_w_header">{t('ct.end.location')}</p>
                  <div className="input_div pointer" name="end_location_is" onClick={this.openMap}>
                    <input type="text" placeholder={t('ct.end.location')} id="end_location" className="journey_input pointer"
                      value={this.state.endLocation} disabled></input>
                    <img src="images/cj_location.png" width="15px"></img>
                  </div>
                </Grid>
              </Grid>

              <Grid container>
                <Grid container item xs={4}>
                  <Grid item xs={5}>
                    <p className="BSC_semi_bold18">{t('ct.datetime')}</p>
                    <p className="cj_w_header">{t('ct.startdate')}</p>
                    <div className="input_div pointer" onClick={this.showStartDate}>
                      <input type="text" placeholder={t('ct.startdate')} id="start_date" value={this.dateFormate(this.state.startDate)} className="journey_input pointer" disabled></input>
                      <img src="images/fill-111.png"></img>
                    </div>
                    <div className="calendar_parent" id="start_date_cal">
                      <Calendar
                        selectRange={false}
                        onChange={this.StartdateClicked}
                        value={this.state.date}
                        minDate={this.state.date}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={5} style={{ position: "relative" }}>
                    <p>&nbsp;</p>
                    <p className="cj_w_header">{t('ct.starttime')}</p>
                    <div className="input_div pointer" onClick={this.showTimePicker}>
                      <input type="text" placeholder={t('ct.starttime')} id="start_time" value={this.state.startTime} className="journey_input pointer" disabled></input>
                      <img src="images/cj_time.png"></img>
                    </div>
                    <div className="time_picker">
                      <Grid container>
                        <Grid item xs={4}>
                          <select className="time_select" id="startHours">
                            <option>1</option><option>2</option><option>3</option><option>4</option>
                            <option>5</option><option>6</option><option>7</option><option>8</option>
                            <option>9</option><option>10</option><option>11</option><option>12</option>
                          </select>
                        </Grid>
                        <Grid item xs={4}>
                          <select className="time_select" id="startMins">
                            <option>00</option><option>10</option><option>20</option>
                            <option>30</option><option>40</option><option>50</option><option>60</option>
                          </select>
                        </Grid>
                        <Grid item xs={4}>
                          <select className="time_select" id="startAmPm">
                            <option>am</option><option>pm</option>
                          </select>
                        </Grid>
                        <Grid item xs>
                          <p className="p_margin0 close_time_picker" onClick={this.startTimeSelected}>{t('ct.done')}</p>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={2}>

                </Grid>
                <Grid container item xs={4}>
                  <Grid item xs={5}>
                    <p>&nbsp;</p>
                    <p className="cj_w_header">{t('ct.enddate')}</p>
                    <div className="input_div pointer" onClick={this.showEndDate}>
                      <input type="text" placeholder={t('ct.enddate')} id="end_date" value={this.dateFormate(this.state.endDate)} className="journey_input pointer" disabled></input>
                      <img src="images/fill-111.png"></img>
                    </div>
                    <div className="calendar_parent" id="end_date_cal">
                      <Calendar
                        selectRange={false}
                        onChange={this.EnddateClicked}
                        value={this.state.date}
                        minDate={this.state.date}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={5} style={{ position: "relative" }}>
                    <p>&nbsp;</p>
                    <p className="cj_w_header">{t('ct.endtime')}</p>
                    <div className="input_div pointer" onClick={this.showTimePicker}>
                      <input type="text" placeholder={t('ct.endtime')} id="end_time" value={this.state.endTime} className="journey_input pointer" disabled></input>
                      <img src="images/cj_time.png"></img>
                    </div>
                    <div className="time_picker">
                      <Grid container>
                        <Grid item xs={4}>
                          <select className="time_select" id="endHours">
                            <option>1</option><option>2</option><option>3</option><option>4</option>
                            <option>5</option><option>6</option><option>7</option><option>8</option>
                            <option>9</option><option>10</option><option>11</option><option>12</option>
                          </select>
                        </Grid>
                        <Grid item xs={4}>
                          <select className="time_select" id="endMins">
                            <option>00</option><option>10</option><option>20</option>
                            <option>30</option><option>40</option><option>50</option><option>60</option>
                          </select>
                        </Grid>
                        <Grid item xs={4}>
                          <select className="time_select" id="endAmPm">
                            <option>am</option><option>pm</option>
                          </select>
                        </Grid>
                        <Grid item xs>
                          <p className="p_margin0 close_time_picker" onClick={this.endTimeSelected}>Done</p>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
              
                <Grid item xs={2}>
                    <p className="cj_w_header">Recurring Journey</p>
                    <input type="checkbox" onChange={(e) => {
                      if(e.currentTarget.checked){
                        $(e.currentTarget).parent().next().show()
                        this.setState({
                          isRecurring : true
                        })
                      }else{
                        $(e.currentTarget).parent().next().hide()
                        this.setState({
                          isRecurring : false,
                          recurringDays : '',
                          includeSunday : false
                        })
                      }
                    }}></input>
                </Grid>
                <Grid container item xs spacing={2} style={{display : "none"}}>
                    <Grid item xs={2}>
                      <p className="cj_w_header">Enter Number Of Days</p>
                      <input type="number" className="journey_input" placeholder="Enter Recurring Days" onChange={(e) => {
                          if(e.currentTarget.value > 90){
                            e.currentTarget.value = ""
                            this.setState({
                              openAlert : true,
                              errorMessage : "You cannot enter more than 90 days"
                            })
                          }else{
                            this.setState({
                              recurringDays : e.currentTarget.value
                            })
                          }
                      }}></input>
                    </Grid>
                    {/* <Grid item xs>
                      <p>&nbsp;</p>
                      <label><input type="checkbox" style={{marginRight : "10px"}} onChange={(e) => {
                        if(e.currentTarget.checked){
                          this.setState({
                            includeSunday : true
                          })
                        }else{
                          this.setState({
                            includeSunday : false
                          })
                        }
                      }}></input>Is Journey On Sunday</label>
                    </Grid> */}
                </Grid>
              </Grid>
              <Grid item xs={2}>
                    <p className="cj_w_header">Is AutoStart</p>
                    <input type="checkbox" onChange={(e) => {
                      if(e.currentTarget.checked){
                        $(e.currentTarget).parent().next().show()
                        this.setState({                          
                          isAutoStart : true
                        })
                      }else{
                        
                        this.setState({
                          isAutoStart : false
                        })
                      }
                    }}></input>
                </Grid>
              <Grid container>
                <Grid container item xs={4}>
                  <Grid item xs={5}>
                    <p className="BSC_semi_bold18">{t('ct.number.stop')}</p>
                    <p className="cj_w_header">{t('ct.stops')} : {this.state.stopElement.length < 10 ? '0' + this.state.stopElement.length : this.state.stopElement.length}</p>
                    <p onClick={this.returNumberOfStop} style={{ color: '#F0762B', cursor: 'pointer' }}>{t('ct.add.stops')}</p>
                  </Grid>
                  <Grid item xs style={{display : this.state.stops.length > 0 ? "" : "none"}}>
                      <p className="cj_w_header">Stop Alert</p>
                      <input type="checkbox" onChange={(e) => {
                        if(e.currentTarget.checked){
                          this.setState({
                            stopAlert : true
                          })
                        }else{
                          this.setState({
                            stopAlert : false
                          })
                        }
                      }}></input>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                {stopContent}
              </Grid>
            </div>

            <div style={{ display: this.state.selectedJourneyType == 1 ? "" : "none" }}>
              <Grid container spacing={3}>
                <Grid item xs>

                  <p>{t('ct.startdate')}</p>
                  <div className="input_div" onClick={this.showStartDate}>
                    <input type="text" placeholder={t('ct.startdate')} id="start_date" value={this.dateFormate(this.state.TstartDate)} className="journey_input" disabled></input>
                    <img src="images/fill-111.png" className="vd_journey_icon"></img>
                  </div>
                  <div className="calendar_parent" id="post_start_date_cal">
                    <Calendar
                      selectRange={false}
                      onChange={this.postStartdateClicked}
                      value={this.state.TstartDate}
                      maxDate={minDate}
                    />
                  </div>
                </Grid>
                <Grid item xs>
                  <p>Start Time</p>
                  <div onClick={this.showStartTimeContent} className="time_div">
                    <p>{this.state.TstartTime}</p>
                  </div>
                  {this.state.showStartTime &&
                    <div style={{ position: "absolute", zIndex: "9" }}>
                      <Timekeeper
                        time={this.state.TstartTime}
                        switchToMinuteOnHourSelect
                        onDoneClick={() => {
                          this.setState({
                            showStartTime: false
                          })
                        }}
                        onChange={this.selectStartTime}
                      />
                    </div>
                  }
                </Grid>
                <Grid item xs>
                  <p>{t('ct.enddate')}</p>
                  <div className="input_div" onClick={this.showEndDate}>
                    <input type="text" placeholder={t('ct.enddate')} id="end_date" value={this.dateFormate(this.state.TendDate)} className="journey_input" disabled></input>
                    <img src="images/fill-111.png" className="vd_journey_icon"></img>
                  </div>
                  <div className="calendar_parent" id="post_end_date_cal">
                    <Calendar
                      selectRange={false}

                      onChange={this.postEnddateClicked}
                      value={this.state.TendDate}
                      maxDate={minDate}
                    />
                  </div>
                </Grid>
                <Grid item xs>
                  <p>End Time</p>
                  <div onClick={this.showEndTimeContent} className="time_div">
                    <p>{this.state.TendTime}</p>
                  </div>
                  {this.state.showEndTime &&
                    <div style={{ position: "absolute", zIndex: "9" }}>
                      <Timekeeper
                        time={this.state.TendTime}
                        switchToMinuteOnHourSelect
                        onDoneClick={() => {
                          this.setState({
                            showEndTime: false
                          })
                        }}
                        onChange={this.selectEndTime}
                      />
                    </div>
                  }
                </Grid>
              </Grid>
              <p className="p_header v_start_journey" onClick={this.assignVehicleDriver}>Assign Vehicle and Driver</p>
              <Grid container spacing={3} id="driver_vehicle_parent">
                {driver_vehicle}
                <div id="vehicle_list" className="dropdown_div">
                  {vehicle_list}
                </div>
                <div id="driver_list" className="dropdown_div">
                  {driver_list}
                </div>
              </Grid>
              <p style={{ display: postJourneyContent.length > 0 ? "" : "none" }} className="cj_w_header"><b>Total Assigned Vehicle and Driver : {CommonFunction.zeroAppend(postJourneyContent.length)}</b></p>
            </div>

          </div>
          {/* end */}
          <div className="btn_div">
            <button className="btn sj_btn" onClick={this.addVehicleDriver} style={{ display: this.state.selectedJourneyType == 0 ? "" : "none" }}>{t('ct.save.proceed')}</button>
            <button className="btn sj_btn" onClick={this.createPostJourney} style={{ display: this.state.selectedJourneyType == 1 ? "" : "none" }}>Create Journey</button>
          </div>

          <div className="select_address_parent" style={{ display: this.state.showMapSelect ? '' : 'none' }}>
            <div className="select_address">
              <Grid container>
                <Grid item xs={5}>
                  <div className="enter_address_div">
                    <p className="p_big_grey">{t('ct.select.location')}</p>
                    <p>{t('ct.location')}</p>
                    <input type="text" defaultValue={this.state.address_is} placeholder={t('ct.enter.address')} className="journey_input"
                      onKeyUp={this.mmiGetAddressApi} id="search_address_val" autoComplete="off"></input>
                    <div className="address_list" id="address_list">
                      {address_list}
                    </div>
                    <button className="btn sj_btn save_address" onClick={this.saveAddress}>{t('ct.save')}</button>
                  </div>
                </Grid>
                <Grid item xs={7}>
                  {this.state.MapContent}
                </Grid>
              </Grid>
              <div className="close_modal" style={{ zIndex: "999" }} onClick={this.closeMapModal}>
                <i class="fa fa-close" aria-hidden="true"></i>
              </div>
            </div>
          </div>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          autoHideDuration={2000}
          onClose={(e, reason) => {
            if (reason === 'clickaway') {
              return;
            }
            this.setState({ openAlert: false })
          }
          }
          open={this.state.openAlert}
          message={this.state.errorMessage}
        />
      </div>
    );
  }
}

export default withTranslation()(CreateJourneyDetails);
