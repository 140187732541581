import React, { Component } from 'react';
import './widget.scss'
import $ from 'jquery';
import Grid from '@material-ui/core/Grid';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import Map from "mapmyindia-react";
import Map from '../map/map'
import ApiUrlCall from '../API/apiurl';
import CircularProgress from '@material-ui/core/CircularProgress';
import { count } from '@progress/kendo-data-query/dist/npm/array.operators';
import Table from 'react-bootstrap/Table'
import { withTranslation } from 'react-i18next';
import CommonFunction from '../common_function';
import * as XLSX from 'xlsx';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import Tooltip from '@material-ui/core/Tooltip';


const L = window.L
const MapmyIndia = window.MapmyIndia
const CONNECTION_LOST = 1
const MOVING = 2
const IDLING = 3
const LOW_SATELLITES = 5
const SLEEPING = 6
const OVERSPEEDING = 7
const PARKED = 4

const START = 16
const SHUTDOWN = 17
const ALL_DGCONNECTION_LOST = 444
const DNT = 8
const PARKED_UNKNOWN = 10
const ALL = [MOVING, IDLING, PARKED,START, OVERSPEEDING, CONNECTION_LOST,ALL_DGCONNECTION_LOST,SHUTDOWN, LOW_SATELLITES, SLEEPING, DNT , PARKED_UNKNOWN]

const MOVING_ICON = 'images/vehicle_icon/Car moving.png'
const DG_OFF_ICON = 'images/vehicle_icon/Car DG OFF.png'
const IDLING_ICON = 'images/vehicle_icon/Car idling.png'
const PARKED_ICON = 'images/vehicle_icon/Car parked.png'
const START_ICON =  'images/vehicle_icon/Car DG ON.png'
const DG_LOST_ICON = 'images/vehicle_icon/Car DG LOST connection.png'

const CONNECTION_LOST_ICON = 'images/vehicle_icon/Car lost connection.png'



class FleetSummary extends Component {

  constructor(props) {
    super(props);
    this.state = {
      fleetData: '',
      summaryData : '',
    }
  }

  componentDidMount() {
    // this.getFleetData()
    this.getSummaryData()
  }




  // fsStatus = (e) => {
  //   const divShowValue = $(e.currentTarget).attr("name")
  //   const divShow = document.getElementById(divShowValue)
  //   $(e.currentTarget).addClass('selected_tab')
  //   $(e.currentTarget).parent().siblings().children().removeClass('selected_tab')
  //   $('#' + divShowValue + '.wid_fs_parent').show()
  //   $('#' + divShowValue + '.wid_fs_parent').siblings().hide()
  // }
  getFleetData = () => {    
    ApiUrlCall.getFleetSummarySetting().then(response => {    
      if (response.data.response_code === 200 && response.data.is_success === true) {
        this.setState({
          fleetData: response.data.data
        })
      }
    }).catch(error => {
      console.log('error while fetching fleet data', error)
    })
  }
  
  getSummaryData = () => {
    ApiUrlCall.widgetFleetSummary().then(response => {      
      // console.log('response is', response)  
      if (response.data.response_code === 200 && response.data.is_success === true) {
         //console.log('fleet count is', response.data)
        this.setState({
          summaryData : response.data.data
        })
      }
    }).catch(error => {
      console.log('error while fetching fleet summary data', error)
    })
  }

  render() {
    let { fleetData, summaryData } = this.state
    const {t} = this.props
    return (

      <div className="cardView button_parent" onClick={() => {
        this.props.data.history.push("/list_vehicle")
      }} style={{cursor : "pointer"}}>
        <Grid container className="">
          <Grid item xs style={{ textAlign: "left" }}>
            <label className="widget-header">{t('dw.f.header')}</label>
          </Grid>
          <Grid item xs style={{ textAlign: "right", fontSize: "16px" }}>
            <b>{summaryData.total < 10 ? '0' + summaryData.total : summaryData.total}</b>&nbsp;&nbsp;<i><img src="images/vehicle-1.png" height="30px" alt="icon"></img></i>
          </Grid>
        </Grid>        
        <hr className="custom-hr"/>
        <div className="fs_parent_div">
          <Grid container className="wid_fs_parent" id="moving_data">
            <Grid container item xs={6} className="wid_fs">
              <p className="count_style">{summaryData.in_transit < 10 ? '0'+summaryData.in_transit : summaryData.in_transit}</p>
              <p className="status_vehicle">{t('dw.f.intransit')}</p>
            </Grid>
            <Grid container item xs={6} className="wid_fs">
              <p className="count_style">{summaryData.assigned_vehicles < 10 ? '0'+summaryData.assigned_vehicles : summaryData.assigned_vehicles}</p>
              <p className="status_vehicle">{t('dw.f.ass')}</p>
            </Grid>
            <Grid container item xs={6} className="wid_fs">
              <p className="count_style">{summaryData.unassigned_vehicles < 10 ? '0'+summaryData.unassigned_vehicles : summaryData.unassigned_vehicles}</p>
              <p className="status_vehicle">{t('dw.f.uass')}</p>
            </Grid>
            <Grid container item xs={6} className="wid_fs">
              <p className="count_style">{summaryData.servicing < 10 ? '0'+summaryData.servicing : summaryData.servicing}</p>
              <p className="status_vehicle">{t('dw.f.ser')}</p>
            </Grid>
          </Grid>          
        </div>
      </div>

    );
  }
}


class MapView extends Component {
  interval = 0;
  _exporter1_table;
  _exporter;

  constructor(props) {
    super(props);
    this.state = {
    
     
      open: false,
      clusternameOpen: false,

      vehicleLiveTrack: '',
      dgvehicleLiveTrack: '',
      vehicleLiveTrack_cluster: [],
      clusterData: [] ,// State to hold cluster data,

      vehicleLiveTrackStatus: '',
      selectedCluster: '',

      vehicleLiveTrackDGStatus: '',
      getAllDigiState_excel_data :[],
      vehicle_getAllDigiState_excel_data:[],
      markers: [],
      mapIntialized: true,
      movingCount: 0,
      idlingCount: 0,
      parkedCount: 0,

      startCount: 0,
      shutdownCount: 0,
      connection_lostCount: 0,
      dgconnection_lostCount:0,
      boundLat: [],
      boundLng: [],
      selectedOption: ALL,
      boundFlag: true,
      clickMessage: "",
      nextCountFormap: 0,

      nextCountFormapdg: 0,
      isEnd: false,
      dgisEnd: false,
      all_count: 0,
      dgall_count:0,
      isDGView: false, // Add this state variable
      date: new Date(),
      isVehicleView: true, // Initially set to true to show Vehicle View
      activeView :'Vehicle_view',

    }
  }

  handleChange = (event) => {
    const selectedCluster = event.target.value;
    this.setState({ selectedCluster }, () => {
      this.getVehicleMarker(false);
    });
  };
  
  componentDidMount() {
    $('.map_loading').show();
    this.setIntervalLiveData();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  componentDidCatch(error, errorInfo) {
   // console.log(error, 'component caught error', errorInfo);
  }

  setIntervalLiveData = () => {
   // console.log("first call");
    this.getVehicleMarker();
    this.dgCluster_reports();
    // this.interval = setInterval(this.getVehicleMarker, 10000);
  };

  clearDataFetchingInterval = () => {
    clearInterval(this.interval);
  };

  startVehicleDataFetching = () => {
   // console.log("second call");
    this.getVehicleMarker();
    this.dgCluster_reports();
    // this.interval = setInterval(this.getVehicleMarker, 10000);
  };

  startDGDataFetching = () => {
  //  console.log("DG data fetching call");
    this.getVehicleMarkerDG();
    // this.interval = setInterval(this.getVehicleMarkerDG, 10000);
  };
  // };
  


  
  dataViewClick = (e) => {
    const vehicleClick = "Vehicle_view";
    const dgClick = "DG_view";
  
    // Add/remove active class to tabs for UI indication
    $(e.currentTarget).addClass('active_widget_btn');
    $(e.currentTarget).removeClass('inactive_widget_btn');
    $(e.currentTarget).siblings().addClass('inactive_widget_btn');
    $(e.currentTarget).siblings().removeClass('active_widget_btn');
  
    // Clear any ongoing data fetching intervals to prevent overlap
    this.clearDataFetchingInterval();
  
    const isZoomedIn = $('body').css('pointerEvents') === 'none';
  
    if (e.currentTarget.id === vehicleClick) {
      // Switch to Vehicle View
      document.getElementById("vehicle_view_div").style.display = "block";
      document.getElementById("DG_tabs").style.display = "none";
      document.getElementById("legend").style.display = "block";  // Show the legend
      document.getElementById("DGviewed").style.display = "none";  // Hide DGviewed
      this.setState({ activeView: "Vehicle_view", isDGView: false, markers: [] }, () => {
        this.startVehicleDataFetching();
      });
    } else if (e.currentTarget.id === dgClick) {
      // Switch to DG View
      document.getElementById("DG_tabs").style.display = "block";
      document.getElementById("legend").style.display = "none";  // Hide the legend
      document.getElementById("DGviewed").style.display = "block";  // Show DGviewed
      document.getElementById("vehicle_view_div").style.display = "none";
      this.setState({ activeView: "DG_view", isDGView: true, markers: [] }, () => {
        this.startDGDataFetching();
      });
    }
  };





  allVehicle = (e) => {
    $(e.currentTarget).addClass('selected_tab')
    $(e.currentTarget).parent().siblings().children().removeClass('selected_tab')
    this.setState({
      markers: [],
      selectedOption: ALL,
      boundFlag : true,
    }, () => {
      //this.updateMarker(ALL)
      this.createMarker(ALL)
    })
  }
  movingVehicle = (e) => {
    if (this.state.movingCount > 0) {      
      $(e.currentTarget).addClass('selected_tab')
      $(e.currentTarget).parent().siblings().children().removeClass('selected_tab')
      this.setState({
        markers: [],
        selectedOption: [MOVING, OVERSPEEDING],
        clickMessage : "",
        boundFlag : true,
      }, () => {
        this.createMarker([MOVING, OVERSPEEDING])
      })
    } else {
      this.setState({
        clickMessage : "Currently No Vehicle is in Moving State*"
      })
    }
  }

  idlingVehicle = (e) => {
    if (this.state.idlingCount > 0) {
      $(e.currentTarget).addClass('selected_tab')
      $(e.currentTarget).parent().siblings().children().removeClass('selected_tab')
      this.setState({
        markers: [],
        selectedOption: [IDLING],
        clickMessage : "",
        boundFlag : true,
      }, () => {
        this.createMarker([IDLING])
      })
    }else{
      this.setState({
        clickMessage : "Currently No Vehicle is in Idling State*"
      })
    }
  }

  parkedVehicle = (e) => {
    if (this.state.parkedCount > 0) {
      $(e.currentTarget).addClass('selected_tab')
      $(e.currentTarget).parent().siblings().children().removeClass('selected_tab')
      this.setState({
        markers: [],
        selectedOption: [PARKED],
        clickMessage : "",
        boundFlag : true,
      }, () => {
        this.createMarker([PARKED])
      })
    }else{
      this.setState({
        clickMessage : "Currently No Vehicle is in Parked State*"
      })
    }
  }

// ******************************************************  DG ***************************************************************
  DgallVehicle = (e) => {
    $(e.currentTarget).addClass('selected_tab')
    $(e.currentTarget).parent().siblings().children().removeClass('selected_tab')
    this.setState({
      markers: [],
      selectedOption: ALL,
      boundFlag : true,
    }, () => {
      //this.updateMarker(ALL)
      this.createMarkerDG(ALL)
    })
  }

  starts = (e) => {
  //  console.log("start button ")
    if (this.state.startCount > 0) {
      $(e.currentTarget).addClass('selected_tab')
      $(e.currentTarget).parent().siblings().children().removeClass('selected_tab')
      this.setState({
        markers: [],
        selectedOption: [START],
        clickMessage : "",
        boundFlag : true,
      }, () => {
        this.createMarkerDG([START])
      })
    }else{
      this.setState({
        clickMessage : "Currently No Vehicle is in Start State*"
      })
    }
  }

  shutdowns = (e) => {
   // console.log("shutdowns")
      if (this.state.shutdownCount > 0) {
      $(e.currentTarget).addClass('selected_tab')
      $(e.currentTarget).parent().siblings().children().removeClass('selected_tab')
      this.setState({
        markers: [],
        selectedOption: [SHUTDOWN],
        clickMessage : "",  
        boundFlag : true,
      }, () => {
        this.createMarkerDG([SHUTDOWN])
      })
    }else{
      this.setState({
        clickMessage : "Currently No Vehicle is in Shutdown State*"
      })
    }
  }

  connectionlostVehicle = (e) => {
    if (this.state.connection_lostCount > 0) {
      $(e.currentTarget).addClass('selected_tab')
      $(e.currentTarget).parent().siblings().children().removeClass('selected_tab')
      this.setState({
        markers: [],
        selectedOption: [CONNECTION_LOST],
        clickMessage : "",
        boundFlag : true,
      }, () => {
        this.createMarker([CONNECTION_LOST])
        //this.updateMarker([PARKED])
      })
    }else{
      this.setState({
        clickMessage : "Currently No Vehicle is in Connection Lost State*"
      })
    }
  }


  dgconnectionlostVehicle = (e) => {
    if (this.state.dgconnection_lostCount > 0) {
      $(e.currentTarget).addClass('selected_tab')
      $(e.currentTarget).parent().siblings().children().removeClass('selected_tab')
      this.setState({
        markers: [],
        selectedOption: [ALL_DGCONNECTION_LOST],
        clickMessage : "",
        boundFlag : true,
      }, () => {
        this.createMarkerDG([ALL_DGCONNECTION_LOST])
      })
    }else{
      this.setState({
        clickMessage : "Currently No Vehicle is in Connection Lost State*"
      })
    }
  }

  stateCount = (state_markers) => {
    var moving = 0
    var idling = 0
    var parked = 0
    var connection_lostCount = 0
    

    if (state_markers) {
      for (let i = 0; i < state_markers.length; i++) {
          if(state_markers[i].tracker_state === CONNECTION_LOST){
            connection_lostCount = connection_lostCount + 1
          }
          else if (state_markers[i].vehicle_state === MOVING || state_markers[i].vehicle_state === OVERSPEEDING) {
            moving = moving + 1
          } else if (state_markers[i].vehicle_state === IDLING) {
            idling = idling + 1
          }
          else if (state_markers[i].vehicle_state === PARKED || state_markers[i].vehicle_state === PARKED_UNKNOWN) {
            parked = parked + 1
          }
      }
    }
    this.setState({
      movingCount: moving,
      idlingCount: idling,
      parkedCount: parked,
      connection_lostCount : connection_lostCount,    
    })
  }

  stateCountdg = (dgstate_markers) => {
    var start = 0
    var shutdown = 0
    var connection_lostDg = 0
   // console.log("hello")

    if (dgstate_markers) {
      //console.log("********")

      for (let i = 0; i < dgstate_markers.length; i++) {
     //   console.log("dgstate_markers",dgstate_markers)
      //  console.log("dgstate_markers",dgstate_markers[i].vehicle_state)
          if(dgstate_markers[i].vehicle_state === ALL_DGCONNECTION_LOST){
            connection_lostDg = connection_lostDg + 1
          }
          else if (dgstate_markers[i].vehicle_state === START ) {

 
            start = start + 1
         //   console.log("start",start)
          }
           else if (dgstate_markers[i].vehicle_state === SHUTDOWN ) {
          //  console.log("shutdown" ,shutdown)
            shutdown = shutdown + 1
          }
         
      }
    }
    this.setState({
    
      startCount: start,
      shutdownCount: shutdown,
      dgconnection_lostCount : connection_lostDg, 
     

    })
  }

  getAddress1 = (objectvehicle,index) => {
    let addressLatLng = { lat:objectvehicle.lat, lng:objectvehicle.lng }
    ApiUrlCall.getAddressMMI(JSON.stringify(addressLatLng)).then(response => {
          objectvehicle["address"]=response.data.data.address
          let newvehicleListtrack =[...this.state.vehicleLiveTrack]
          
          newvehicleListtrack[index]["address"]=response.data.data.address
          this.setState({
            vehicleLiveTrack:newvehicleListtrack
          })
            })
        }

  updateMarker =(vstate) =>  {
          let timeduration = this.state.vehicleLiveTrack.length*300
          for (let i = 0; i < this.state.vehicleLiveTrack.length; i++) {
            this.getAddress1(this.state.vehicleLiveTrack[i],i)
          }
          setTimeout(function(){
            this.createMarker(ALL)
          }.bind(this),timeduration)
        }


      componentDidUpdate(prevProps, prevState) {
        if (prevState.selectedCluster !== this.state.selectedCluster) {
          $('.map_loading').show(); // Show the loading indicator when selectedCluster changes
       //  this.getVehicleMarker(false);
        }
      }

          
getVehicleMarker = (isUpdate) => {
  if (this.state.isDGView) return; // Skip if DG view is active

  const { nextCountFormap, selectedCluster } = this.state;

  ApiUrlCall.getAllVehicleLiveTrackingNew({ next_count: nextCountFormap, dg_cluster_name: selectedCluster })
    .then(response => {
      if (response.data.response_code === 200 && response.data.is_success) {
        this.setState({
          vehicleLiveTrack: response.data.data.live_track,
          nextCountFormap: response.data.data.next_count,
          isEnd: response.data.data.is_end,
          all_count: response.data.data.total_count,
        }, () => {
          if (this.state.isEnd) {
            this.onFeatchVehiclesCompletion();
          } else {
            this.getVehicleMarker();
          }

          if (this.state.vehicleLiveTrack) {
            this.createMarker(this.state.selectedOption, isUpdate);
          }
        });
      }
    })
    .catch(error => {
      console.log('error while fetching data is', error.response);
      this.getVehicleMarker();
    })
    .finally(() => {
      // Hide the loading indicator in both success and error cases
      $('.map_loading').hide();
    });

  ApiUrlCall.getAllVehicleState({ dg_cluster_name: selectedCluster })
    .then(response => {
      if (response.data.response_code === 200 && response.data.is_success) {
        this.setState({
          vehicleLiveTrackStatus: response.data.data.live_track,
        }, () => {
          if (this.state.vehicleLiveTrackStatus) {
            this.stateCount(this.state.vehicleLiveTrackStatus);
          }
        });
      }
    })
    .catch(error => {
      console.log('error while fetching data is', error.response);
    });
}

  dgCluster_reports = () => {

  var user_id = localStorage.getItem("user_id")
  
      ApiUrlCall.cluster_Name(user_id).then(response => {
        
  //  console.log("responce  cluster list", response) 
         if (response.data.response_code == 200) {
        
          this.setState({
            vehicleLiveTrack_cluster: response.data.data,
            clusterData: response.data.data,

       
          })
      
       }
      }) 
  }

  onFeatchVehiclesCompletion = () => {
    setTimeout(() => {
      this.setState({
        nextCountFormap: 0,
        isEnd: false,
        vehicleLiveTrack: [],
          
      }, () => {
        this.getVehicleMarker(true);
      });
    }, 5000);
  }


  getVehicleMarkerDG = (isUpdate) => {

    if (!this.state.isDGView) return; // Skip if not in DG view

   const { nextCountFormapdg, selectedCluster} = this.state;

   //console.log("Dg count",nextCountFormapdg,"selectedCluster=",selectedCluster);

   ApiUrlCall.getAllVehicleLiveTrackingDGreports({  next_count: nextCountFormapdg, dg_cluster_name: selectedCluster}).then(response => {
   //  console.log("DG Api responce ", response)
      if (response.data.response_code === 200 && response.data.is_success) {
    
        this.setState({

          dgvehicleLiveTrack: response.data.data.report_list,
          nextCountFormapdg: response.data.data.next_count,
          dgisEnd: response.data.data.is_end,
          dgall_count: response.data.data.total_count
        }, () => {
          $('.map_loading').hide();
          if (this.state.dgisEnd) {
            this.onFeatchVehiclesCompletionDG();
          } else {
            this.getVehicleMarkerDG();
          }
          if (this.state.dgvehicleLiveTrack) {
            this.createMarkerDG(this.state.selectedOption, isUpdate);
          this.stateCountdg(this.state.dgvehicleLiveTrack);
         
          }
        });
      } 
    }).catch(error => {
      console.log('error while fetching data is', error.response);
      this.getVehicleMarkerDG();
    });

  }

  onFeatchVehiclesCompletionDG = () => {
    //console.log('onFeatchVehiclesCompletionDG DGGGGGG');
    setTimeout(() => {
      this.setState({
        nextCountFormapdg: 0,
        dgisEnd: false,
        dgvehicleLiveTrack: [],
        vehicleLiveTrack: [],
      }, () => {
        this.getVehicleMarkerDG(true);
      });
    }, 5000);
  }
  createMarker = (vehicleState,isUpdate) => {
    let array = []
    if (isUpdate == undefined  ){
    array = [...this.state.markers]
    }
    const icon = [MOVING_ICON, IDLING_ICON, PARKED_ICON, CONNECTION_LOST_ICON]
    let liveVehicleData = this.state.vehicleLiveTrack
    if (this.state.vehicleLiveTrack) {
      for (let i = 0; i < liveVehicleData.length; i++) {        
        if (vehicleState.includes(liveVehicleData[i].vehicle_state)) {
        var data = { name: this.state.vehicleLiveTrack[i].name }
        
        array.push(
          {
            position: [this.state.vehicleLiveTrack[i].lat, this.state.vehicleLiveTrack[i].lng],
            rotateMarker: true,
            icon: L.icon({
              iconUrl: ApiUrlCall.getVehicleIcon(this.state.vehicleLiveTrack[i].vehicle_type_id, 
                this.state.vehicleLiveTrack[i].vehicle_state,this.state.vehicleLiveTrack[i].tracker_state),
              iconRetinaUrl: ApiUrlCall.getVehicleIcon(this.state.vehicleLiveTrack[i].vehicle_type_id, 
                  this.state.vehicleLiveTrack[i].vehicle_state,this.state.vehicleLiveTrack[i].tracker_state),
              iconSize: [40, 40],
              popupAnchor: [-3, -15]
            }),
            draggable: false,
            setViewFlag: this.state.setView,
            title: this.state.vehicleLiveTrack[i].name,
            Last_Reported_Time:CommonFunction.tableDateFormate(this.state.vehicleLiveTrack[i].last_reported_time)+', '+CommonFunction.timeFormate(this.state.vehicleLiveTrack[i].last_reported_time),
            battery_percentage: this.state.vehicleLiveTrack[i].battery_percentage,
            serial_number: this.state.vehicleLiveTrack[i].serial_number,
         //   site_types: this.state.dgvehicleLiveTrack[i].site_type,
            latitude: this.state.vehicleLiveTrack[i].lat,
            longitude:this.state.vehicleLiveTrack[i].lng,
            popupData: data,
            vehicle_type_id : this.state.vehicleLiveTrack[i].vehicle_type_id,
            onClick: () => { },
            onDragend: () => { }
          }
        )
        }
      }
    }
    this.setState({
      markers: array,
    }, () => {
      this.boundMarkers()
    })
  }

  createMarkerDG = (vehicleState, isUpdate) => {

  //  console.log("createMarkerDG")
    let array = [];
    if (isUpdate === undefined) {
      array = [...this.state.markers];
    }
    const icon = [MOVING_ICON, START_ICON, DG_OFF_ICON, DG_LOST_ICON, PARKED_ICON,];
    let liveVehicleData = this.state.dgvehicleLiveTrack;
   
    if (this.state.dgvehicleLiveTrack) {
      for (let i = 0; i < liveVehicleData.length; i++) {
        if (vehicleState.includes(liveVehicleData[i].vehicle_state)) {
          var data = { name: this.state.dgvehicleLiveTrack[i].name };
          array.push({
            position: [this.state.dgvehicleLiveTrack[i].lat, this.state.dgvehicleLiveTrack[i].lng],
            rotateMarker: true,
            icon: L.icon({
              iconUrl: ApiUrlCall.getVehicleIcon(this.state.dgvehicleLiveTrack[i].vehicle_type_id,
                this.state.dgvehicleLiveTrack[i].vehicle_state, this.state.dgvehicleLiveTrack[i].tracker_state),
              iconRetinaUrl: ApiUrlCall.getVehicleIcon(this.state.dgvehicleLiveTrack[i].vehicle_type_id,
                this.state.dgvehicleLiveTrack[i].vehicle_state, this.state.dgvehicleLiveTrack[i].tracker_state),
              iconSize: [40, 20],
              popupAnchor: [-3, -15]
            }),
            draggable: false,
            setViewFlag: this.state.setView,
            title: this.state.dgvehicleLiveTrack[i].Name,

            Last_Reported_Time: CommonFunction.tableDateFormate(this.state.dgvehicleLiveTrack[i].last_reported_time) + ', ' + CommonFunction.timeFormate(this.state.dgvehicleLiveTrack[i].last_reported_time),
            
            //battery_percentage: this.state.dgvehicleLiveTrack[i].battery_percentage,
            serial_number: this.state.dgvehicleLiveTrack[i].serial_number_id,
          //   site_types: this.state.dgvehicleLiveTrack[i].site_type,
            latitude: this.state.dgvehicleLiveTrack[i].lat,
            longitude: this.state.dgvehicleLiveTrack[i].lng,
            popupData: data,
            vehicle_type_id: this.state.dgvehicleLiveTrack[i].vehicle_type,
            onClick: () => { },
            onDragend: () => { }
          });
        }
      }
    }
    this.setState({
      markers: array,
    }, () => {
      this.boundMarkers();
    });
  }
  exclereports =()=>{
    ApiUrlCall.Dashbord_excel_dgreport({ }).then(response => { 
//    console.log("excel responce ",response)

    })


  }
  
  zoomInMap = (e) => {
    $(e.currentTarget).parent().parent().parent().addClass('zoom_in_map')
    $(e.currentTarget).siblings().show()
    $(e.currentTarget).hide()
   // $('#legend').show()
   // $('#DGviewed').show()
    $('body').css({
      pointerEvents: "none",
      overflow: "hidden"
    })
    window.dispatchEvent(new Event('resize'));
  }

  zoomOutMap = (e) => {
    $(e.currentTarget).parent().parent().parent().removeClass('zoom_in_map')
    $(e.currentTarget).siblings().show()
    $(e.currentTarget).hide()
    // $('#legend').hide()
    // $('#DGviewed').hide()
    $('body').css({
      pointerEvents: "auto",
      overflow: "auto"
    })
    window.dispatchEvent(new Event('resize'));
  }
  boundMarkers = () => {
    var latitudeArr = []
    var longitudeArr = []
    if(this.state.markers.length > 0){
      this.state.markers.map(b => {
        if (b.position && Array.isArray(b.position)) {
          latitudeArr.push(b.position[0])
          longitudeArr.push(b.position[1])
        }
        this.setState({
          boundLat: latitudeArr,
          boundLng: longitudeArr
        }, () => {
        })
      })
    }
  }
  returnMap = () => {    
    const maps = []    
    if (this.state.markers) {
      if (this.state.markers.length > 0 && this.state.boundLat.length > 0 && this.state.boundLng.length > 0) {
        maps.push(
          <Map
            key={1}
           // height={"82%"}
            markers={this.state.markers}
            bounds={[
              {
                lat: this.state.boundLat,
                lng: this.state.boundLng
              }
            ]}
            pauseBound = {this.state.boundFlag}
            cluster = {true}
            onZoom={() => {
              this.setState({
                boundFlag : false
              })
            }}
          />
        )
      }
    }
    if (maps.length > 0) {
      return maps
    }
  }
  to_save_in_excel_sheet = () => {
    this.save( this._exporter1_table ); 
  }
  createExcelFile = () => {
    const { selectedCluster} = this.state;
    ApiUrlCall.getAllDigiState_excel({dg_cluster_name: selectedCluster}).then(response => {

        const data = response.data;
        console.log( "DG",data);
        this.setState(
          {
            getAllDigiState_excel_data: data
          },
          () => {
            this.generateExcelReport();
          }
        );
      })
      .catch(error => {
        console.log('Error while fetching data:', error);
      });
  };

  generateExcelReport = () => {
    const { getAllDigiState_excel_data } = this.state;

    // Convert data to a worksheet
    const ws = XLSX.utils.json_to_sheet(getAllDigiState_excel_data);

    // Create a new workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'DG_Report');

    // Generate a file name
    const excel_name = `DG_Report_${CommonFunction.downloadReportDate(this.state.date)}.xlsx`;

    // Write the workbook to a binary string
    XLSX.writeFile(wb, excel_name);
  };
  vehicle_createExcelFile = () => {
    const { selectedCluster} = this.state;
    ApiUrlCall.vehicle_getAllDigiState_excel({dg_cluster_name: selectedCluster}).then(response => {
      
        const data = response.data;
        //console.log(data, "*****************");
        console.log( "VH" ,data);

        this.setState(
          {
            vehicle_getAllDigiState_excel_data: data
          },
          () => {
            this.vehicle_generateExcelReport();
          }
        );
      })
      .catch(error => {
        console.log('Error while fetching data:', error);
      });
  };
  vehicle_generateExcelReport = () => {

    const { vehicle_getAllDigiState_excel_data } = this.state;

    // Convert data to a worksheet
    const ws = XLSX.utils.json_to_sheet(vehicle_getAllDigiState_excel_data);

    // Create a new workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Vehicle_Report');

    // Generate a file name
    const excel_name = `vehicle_Report_${CommonFunction.downloadReportDate(this.state.date)}.xlsx`;

    // Write the workbook to a binary string
    XLSX.writeFile(wb, excel_name);
  };


handleCircleChange = (event) => {
  this.setState({ selectedCircle: event.target.value, selectedCluster: '' });
}

handleClusterChange = (event) => {
  this.setState({ selectedCluster: event.target.value });
}



 render() {

  const { t } = this.props;
  const { selectedCluster, selectedCircle, vehicleLiveTrack_cluster, clusterData } = this.state;
  const { open, clusternameOpen } = this.state;
  var excel_name = "DG_Report_" + CommonFunction.downloadReportDate(this.state.date) + ".xlsx";
  excel_name = "vehicle_Report_" + CommonFunction.downloadReportDate(this.state.date) + ".xlsx";

  const circles = clusterData.map(item => item.circlename);
  const clusters = selectedCircle ? clusterData.find(item => item.circlename === selectedCircle).clustername : [];

  return (

    <div className="cardViews button_parent">
      <div>
        <label className="widget-header">{t('vls.map')}</label>
      
          <div className="zoom-icons">
            <img src="images/expand.png" alt="icon" onClick={this.zoomInMap} className="zoom-icon" />
            <img src="images/expand.png" alt="icon" onClick={this.zoomOutMap} className="zoom-icon" style={{ display: "none" }} />
        </div>      
      </div>

      <Grid item xs={12} className="left-align">
        <span className="cj_w_header red-text right-align">{this.state.clickMessage}</span>
      </Grid>

      <div style={{ display: localStorage.getItem('group') == 32 || localStorage.getItem('group') == 33 ? "" : "none" }} className='buttons_div'>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3} style={{ textAlign: "center" }}>
            <div className="dropdown">
              <select className="dropdown-select" value={selectedCircle} onChange={this.handleCircleChange}>
                <option value="">Select a Circle</option>
                {circles.map((circle, index) => (
                  <option key={index} value={circle}>
                    {circle}
                  </option>
                ))}
              </select>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3} style={{ textAlign: "center" }}>
            <div className="dropdown">
              <select className="dropdown-select" value={selectedCluster} onChange={this.handleClusterChange}>
                <option value="">Select a Cluster</option>
                {clusters.map((cluster, index) => (
                  <option key={index} value={cluster}>
                    {cluster}
                  </option>
                ))}
              </select>
            </div>
          </Grid>
          <Grid item xs={12} md={6} style={{ textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div className='dgbuttons'>
              <div className="toggle_btn">
                <div className='excel_report_btn'>
                  <img
                    src="images/group-6-copy-2.png"
                    id="DG_excel"
                    onClick={this.createExcelFile}
                    style={{
                      display: this.state.activeView === "DG_view" && (localStorage.getItem('group') == 32 || localStorage.getItem('group') == 33) ? "" : "none",
                      cursor: "pointer",
                      height: "20px",
                      width: "20px",
                      marginRight: "30px",
                    }}
                  />
                </div>
                <div className="graph_view_btn">
                  <div className="active_widget_btn" style={{ display: "inline-block" }} onClick={this.dataViewClick} id="Vehicle_view">
                    <label>Vehicle View</label>
                  </div>
                  <div className="inactive_widget_btn" style={{ display: "inline-block" }} onClick={this.dataViewClick} id="DG_view">
                    <label>DG View</label>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      

      <div>
        <div id="vehicle_view_div">
          <Grid container spacing={1}>
            <Grid item xs={6} sm>
              <div onClick={this.allVehicle} className="vehicle_state selected_tab">
                <p className="p_margin0"> {t('ct.all.text')}&nbsp;<span>{this.state.all_count < 9 ? '0' + this.state.all_count : this.state.all_count}</span></p>
              </div>
            </Grid>
            <Grid item xs={6} sm>
              <div onClick={this.movingVehicle} className="vehicle_state">
                <p className="p_margin0"> {t('ct.table.h14')}&nbsp;<span>{this.state.movingCount <= 9 ? '0' + this.state.movingCount : this.state.movingCount}</span></p>
              </div>
            </Grid>
            <Grid item xs={6} sm>
              <div onClick={this.idlingVehicle} className="vehicle_state">
                <p className="p_margin0"> {t('ct.table.h15')}&nbsp;<span>{this.state.idlingCount <= 9 ? '0' + this.state.idlingCount : this.state.idlingCount}</span></p>
              </div>
            </Grid>
            <Grid item xs={6} sm>
              <div onClick={this.parkedVehicle} className="vehicle_state">
                <p className="p_margin0"> {t('ct.table.h16')}&nbsp;<span>{this.state.parkedCount <= 9 ? '0' + this.state.parkedCount : this.state.parkedCount}</span></p>
              </div>
            </Grid>
            <Grid item xs={6} sm>
              <div onClick={this.connectionlostVehicle} className="vehicle_state">
                <p className="p_margin0"> {t('Connection Lost')}&nbsp;<span>{this.state.connection_lostCount <= 9 ? '0' + this.state.connection_lostCount : this.state.connection_lostCount}</span></p>
              </div>
            </Grid>
          </Grid>
        </div>
        <div id="DG_tabs" style={{ display: "none" }}>
          <Grid container spacing={1}>
            <Grid item xs={6} sm>
              <div onClick={this.DgallVehicle} className="vehicle_state selected_tab">
                <p className="p_margin0"> DG ALL&nbsp;<span>{this.state.dgall_count < 9 ? '0' + this.state.dgall_count : this.state.dgall_count}</span></p>
              </div>
            </Grid>
            <Grid item xs={6} sm>
              <div onClick={this.starts} className="vehicle_state">
                <p className="p_margin0"> Start &nbsp;<span>{this.state.startCount <= 9 ? '0' + this.state.startCount : this.state.startCount}</span></p>
              </div>
            </Grid>
            <Grid item xs={6} sm>
              <div onClick={this.shutdowns} className="vehicle_state">
                <p className="p_margin0"> Stop &nbsp;<span>{this.state.shutdownCount <= 9 ? '0' + this.state.shutdownCount : this.state.shutdownCount}</span></p>
              </div>
            </Grid>
            <Grid item xs={6} sm>
              <div onClick={this.dgconnectionlostVehicle} className="vehicle_state">
                <p className="p_margin0"> DG Connection Lost &nbsp;<span>{this.state.dgconnection_lostCount <= 9 ? '0' + this.state.dgconnection_lostCount : this.state.dgconnection_lostCount}</span></p>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>

      <div className="statistic_container3" id="legend"   style={{ display: "block" }}>
        <div id="search_legend_container" className="margin-bottom-10">
          <div className="legend_inline">
            <div className="legend_item_inline">
              <div className="legend_4"></div>
              <p className="legend_text_map"><b> Moving</b></p>
            </div>
            <div className="legend_item_inline">
              <div className="legend_77"></div>
              <p className="legend_text_map"><b> Parked</b></p>
            </div>
            <div className="legend_item_inline">
              <div className="legend_5"></div>
              <p className="legend_text_map"><b> Idle</b></p>
            </div>
            <div className="legend_item_inline">
              <div className="legend_9"></div>
              <p className="legend_text_map"><b> Connection Lost</b></p>
            </div>
          </div>
        </div>
      </div>

      <div className="statistic_container4" id="DGviewed" style={{ display: "none" }}>
  <div id="search_legend_container" style={{ marginBottom: "10px" }}>
    <div className="legend_inline">
      <div className="legend_item_inline">
        <div className="legend_4"></div>
        <p className="legend_text_map"><b>Start</b></p>
      </div>
      <div className="legend_item_inline">
        <div className="legend_77"></div>
        <p className="legend_text_map"><b>Stop</b></p>
      </div>
      <div className="legend_item_inline">
        <div className="legend_9"></div>
        <p className="legend_text_map"><b>Connection Lost</b></p>
      </div>
    </div>
  </div>
</div>

      <div className="widget-mapview">
        {this.returnMap()}
        <p className="no_data_map" style={{ display: this.state.markers.length > 0 ? "none" : "" }}>Updating Current Status.....</p>
      </div>
      <div className="map_loading">
        <CircularProgress className="map_loading_icon" />
      </div>
    </div>

);
}
}
  export const FleetSummary1 = withTranslation()(FleetSummary);
  export const MapView1 = withTranslation()(MapView);


